<template>
  <v-card class="p-2" flat>
    <template slot="progress">
      <v-progress-linear absolute bottom color="#01a7e3" height="5" indeterminate rounded></v-progress-linear>
    </template>
    <div>
      <v-text-field v-model="localFranchisee.name" label="Nume Firma" />
      <error-messages :errors="errors.name" />
    </div>

    <div class="mb-4">
      <v-text-field v-model="localFranchisee.domain" label="Domeniu Portal Shop" />
    </div>

    <div class="mb-4">
      <v-text-field v-model="localFranchisee.admin_domain" label="Domeniu Portal Admin" />
    </div>

    <div class="mb-4">
      <v-text-field v-model="localFranchisee.phone" label="Nr. Telefon" />
      <error-messages :errors="errors.phone" />
    </div>

    <div class="mb-4">
      <v-text-field v-model="localFranchisee.email" label="Email" />
      <error-messages :errors="errors.email" />
    </div>
  </v-card>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import _ from 'lodash'

export default {
  name: 'FranchiseeCompanyInformation',
  components: {
    ErrorMessages
  },
  data() {
    return {
      errors: {},
      localFranchisee: {}
    }
  },
  computed: {
    franchisee() {
      return this.$store.getters['singleFranchisee/item']
    }
  },
  methods: {
    save() {
      return new Promise((resolve, reject) => {
        this.$http.patch(`clients/franchisees/${this.franchisee.id}`, this.localFranchisee)
          .then(() => {
            this.errors = {}
            resolve()
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            reject(error)
          })
      })

    }
  },
  watch: {
    franchisee: {
      deep: true,
      handler(value) {
        this.localFranchisee = _.cloneDeep(value)
      }
    }
  }
}
</script>
