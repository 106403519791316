<template>
    <div class="pr-4 pl-4">
        <div class="row align-center">
            <div class="col-sm-12  col-lg-9">
                <p class="branding-color-text ">{{ textColorLabel }}</p>
            </div>
            <div class="col-sm-12  col-lg-3 d-flex justify-end">
                <input class="color-input-field farbtastic-hex text-box single-line colorwell-selected" :id="colorValue"
                    type="text" :value="colourScheme"
                    style="opacity: 1; background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);"
                    :style="{ 'background-color': '' + colourScheme + '' }"
                    @change="colourScheme = $event.target.value, $emit('selectedValueColorChange', $event.target.value, colorValue)"
                    @click="colourScheme = $event.target.value, $emit('selectedValueColorChange', $event.target.value, colorValue)">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Input',
    props: ['textColorLabel', 'colourScheme', 'selectedValueColorChange', 'colorValue']
}
</script>