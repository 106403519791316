<template>
  <v-sheet>
    <cit-data-table :fixed-filters="{
      franchisee_id: $route.params.id
    }"
    :headers="headers" :includes="[
      'customersCount'
    ]" resource-path="users/franchisee">

      <template v-slot:actions>
        <create-form  />
      </template>


      <template v-slot:item.actions="{ item }">

        <view-button :to="{ name: 'view-user', params: { id: item.id } }" target="_self" />

        <action-delete :action-url="`users/${item.id}`" />

        <reset-password-button :user="item" />

      </template>

    </cit-data-table>
  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import ViewButton from '@/components/general-form/ViewButton'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'
import ResetPasswordButton from '@/views/Users/ResetPasswordButton'
import CreateForm from './Create'

export default {
  name: 'Agents',
  components: {
    ResetPasswordButton,
    ActionDelete,
    ViewButton,
    CitDataTable,
    CreateForm
  },
  data() {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          width: '65'
        },
        {
          text: 'Avatar',
          value: 'avatar',
          display: 'avatar',
          align: 'center',
          width: '100',
          sortable: false
        },
        {
          text: 'Nume',
          value: 'full_name',
          filterType: 'text',
          sortable: false
        },
        {
          text: 'Email',
          value: 'email',
          display: 'mail',
          sortable: false
        },
        {
          text: 'Phone',
          value: 'phone',
          width: '150',
          align: 'center',
          display: 'phone',
          sortable: false
        }
      ]
    }
  }
}
</script>
