<template>
    <div :class="open ? 'accordion-section open' : 'accordion-section'">
        <div class="branding-header-section" @click.prevent="open = !open">
            <h4 class="branding-header-text">{{ textColorLabelHeading }}</h4>
        </div>
        <div class="branding-body-section" v-show="open">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    data: () => {
        return {
            open: false
        }
    },
    name: 'Accordion',
    props: {
        textColorLabelHeading: String,
    }
}
</script>