<template>
  <v-sheet>
    <template slot="progress">

      <loader :loading="loading" colors="rgba(255, 255, 255, 0.90)" loader-color="#f0483d" size="fa-6x"></loader>

    </template>
    <v-card class="p-2" flat>

      <v-row class="mb-4 mt-5">
        <v-col cols="6">
          <h4 class=" mb-4 ">Setari social logins</h4>
          <div class="mb-4">
            <v-checkbox v-model="useSocialLogins" label="Folosesc social logins" />
            <error-messages :errors="errors.useSocialLogins" />
            <v-btn :disabled="loadingSave" :loading="loadingSave" color="success" class="primary-button"
              @click="updateUseSocialLoginsSettings">Salveaza</v-btn>            
          </div>
        </v-col>
      </v-row> 
      
      <v-row class="mb-4 mt-5">
        <v-col cols="6">

          <h4 class=" mb-4 ">Google</h4>
          <div class="mb-4">
            <v-text-field v-model="socialMediaLoginSettingsData.google.client_id" label="Client id" />
            <error-messages :errors="errors && errors.googleClient_id" />
          </div>
          <v-text-field v-model="socialMediaLoginSettingsData.google.client_secret" label="Client secret" />
          <error-messages :errors="errors.googleClient_secret" />
          <v-btn :disabled="loadingSave" :loading="loadingSave" color="success" class="primary-button"
            @click="updateSocialLoginSettings('google')">Salveaza</v-btn>
        </v-col>

        <v-col cols="6">
          <h4 class=" mb-4 ">Facebook</h4>
          <div class="mb-4">
            <v-text-field v-model="socialMediaLoginSettingsData.facebook.client_id" label="Client id" />
            <error-messages :errors="errors.facebookClient_id" />
          </div>

          <v-text-field v-model="socialMediaLoginSettingsData.facebook.client_secret" label="Client secret" />
          <error-messages :errors="errors.facebookClient_secret" />
          <v-btn :disabled="loadingSave" :loading="loadingSave" color="success" class="primary-button"
            @click="updateSocialLoginSettings('facebook')">Salveaza</v-btn>          
        </v-col>
      </v-row>

      <v-divider />

      <v-row class="mb-4 mt-5">
        <v-col cols="6">
          <h4 class=" mb-4 ">Tiny MCE API Key</h4>
          <div class="mb-4">
            <v-text-field v-model="tinyMceApiKey" label="API Key" />
            <error-messages :errors="errors.tinyMceApiKey" />
            <v-btn :disabled="loadingSave" :loading="loadingSave" color="success" class="primary-button"
              @click="updateTinyMCESettings">Salveaza</v-btn>            
          </div>
        </v-col>
        <v-col cols="6">
          <h4 class=" mb-4 ">Google Tag Manager Id</h4>
          <div class="mb-4">
            <v-text-field v-model="gtmId" label="GTM Id" />
            <error-messages :errors="errors.gtmId" />
            <v-btn :disabled="loadingSave" :loading="loadingSave" color="success" class="primary-button"
              @click="updateGtmSettings">Salveaza</v-btn>            
          </div>
        </v-col>        
      </v-row>
      <v-row class="mb-4 mt-5">
        <v-col cols="6">
          <h4 class=" mb-4 ">Setari plata</h4>
          <div class="mb-4">
            <v-checkbox v-model="cashOnDelivery" label="Accept Plata la Livrare" />
            <error-messages :errors="errors.cashOnDelivery" />
            <v-btn :disabled="loadingSave" :loading="loadingSave" color="success" class="primary-button"
              @click="updatePaymentSettings">Salveaza</v-btn>            
          </div>
        </v-col>
      </v-row>      
      <!-- <v-divider />
      <v-row class="mt-4">
        <v-col>
          <v-btn :disabled=" loadingSave " :loading=" loadingSave " color="success" class="primary-button"
            @click=" updateEmailServerSettings ">Salveaza</v-btn>
        </v-col>
      </v-row> -->
    </v-card>
  </v-sheet>
</template>

<script>
// import axios from 'axios'
import axios from '@/axios'
import ErrorMessages from '@/components/general-form/ErrorMessages'
let baseURL = process.env.VUE_APP_BASE_URL_API
let baseURLAPP = process.env.VUE_APP_BASE_URL_API

baseURL = `${baseURL}/app`
export default {
  name: 'SocialMediaLoginSettings',
  components: { ErrorMessages },
  data() {
    return {
      errors: {
        googleClient_id: '',
        googleClient_secret: '',
        facebookClient_id: '',
        facebookClient_secret: '',
        tinyMceApiKey: '',
        gtmId: '',
        cashOnDelivery:'',
        useSocialLogins:''
      },
      type:'',
      socialMediaLoginSettingsData: {
        google: {
          client_id: '',
          client_secret: ''
        },
        facebook: {
          client_id: '',
          client_secret: ''
        }
      },
      tinyMceApiKey:'',
      gtmId:'',
      cashOnDelivery: false,
      useSocialLogins: false,
      loading: false,
      loadingSave: false,
    }
  },
  props: {
    franchiseeId: {
      required: true
    }
  },
  methods: {
    loadSocialLoginDetails(isLoading) {
      if (isLoading)
        this.loading = true
      axios
        .get(baseURL + `/clients/franchisees/${this.franchiseeId}/socialLoginDetails`)
        .then(response => {
          if (response.data && response.data.length > 0) {
            const googleSettings = response.data.find(item => item.type === 'google');
            const facebookSettings = response.data.find(item => item.type === 'facebook');
            this.socialMediaLoginSettingsData = {
              google: {
                client_id: googleSettings != undefined ? googleSettings.client_id : '',
                client_secret: googleSettings != undefined ? googleSettings.client_secret : ''
              },
              facebook: {
                client_id: facebookSettings != undefined ? facebookSettings.client_id : '',
                client_secret: facebookSettings != undefined ? facebookSettings.client_secret : ''
              },
            }
          }
          this.loading = false
        })
    },
    loadTinyMceSettings(isLoading) {
      if (isLoading)
        this.loading = true
      axios
        .get(baseURL + `/clients/franchisees/${this.franchiseeId}/tinyMceSettings`)
        .then(response => {
          if (response.data && response.data.length > 0) {
              this.tinyMceApiKey = response.data
          }
          this.loading = false
        })
    },
    loadGtmSettings(isLoading) {
      if (isLoading)
        this.loading = true
      axios
        .get(baseURL + `/clients/franchisees/${this.franchiseeId}/gtmSettings`)
        .then(response => {
          if (response.data && response.data.length > 0) {
              this.gtmId = response.data
          }
          this.loading = false
        })
    },
    loadPaymentSettings(isLoading) {
      if (isLoading)
        this.loading = true
      axios
        .get(baseURL + `/clients/franchisees/${this.franchiseeId}/paymentSettings`)
        .then(response => {
          if (response.data) {
              this.cashOnDelivery = response.data
          }
          this.loading = false
        })
    },
    loadUseSocialLogins(isLoading) {
      if (isLoading)
        this.loading = true
      axios
        .get(baseURL + `/clients/franchisees/${this.franchiseeId}/useSocialLogins`)
        .then(response => {
          if (response.data) {
              this.useSocialLogins = response.data
          }
          this.loading = false
        })
    },
    loadItems(isLoading) {
        if (isLoading)
          this.loading = true
        this.loadSocialLoginDetails()
        this.loadTinyMceSettings()
        this.loadGtmSettings()
        this.loadPaymentSettings()
        this.loadUseSocialLogins()
        this.loading = false
    },     
    updateSocialLoginSettings(type) {
      this.type = type
      const socialMediaLoginSettingsData = [
        {
          type: type,
          client_id: type === 'google' 
                  ? this.socialMediaLoginSettingsData.google.client_id 
                  : this.socialMediaLoginSettingsData.facebook.client_id,
          client_secret: type === 'google' 
                  ?  this.socialMediaLoginSettingsData.google.client_secret 
                  : this.socialMediaLoginSettingsData.facebook.client_secret
        }
      ]
      this.loadingSave = true
      this.$http.post(`clients/franchisees/${this.franchiseeId}/socialLoginDetails`, socialMediaLoginSettingsData)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Updated social login settings!',
            color: 'success'
          })
          this.loadSocialLoginDetails(false)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            console.log(err.response.data.errors)
            const client_id = err.response.data.errors && err.response.data.errors['0.client_id']
            const client_secret = err.response.data.errors && err.response.data.errors['0.client_secret']
            this.type === 'google' 
                  ? this.errors = { googleClient_id: client_id, googleClient_secret: client_secret}
                  : this.errors = { facebookClient_id: client_id, facebookClient_secret: client_secret}
            this.$vs.notify({
              title: 'Eroare!',
              text: 'Nu toate campurile obligatorii au fost completate.',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'A aparut o eroare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
        })
        .finally(() => {
          this.loadingSave = false
        })
    },
    updateTinyMCESettings() {
      this.type = 'tinyMceApiKey';
      const api_key = {api_key: this.tinyMceApiKey}
      this.loadingSave = true
      this.$http.post(`clients/franchisees/${this.franchiseeId}/tinyMceSettings`, api_key)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Updated Tiny MCE api key!',
            color: 'success'
          })
          this.loadTinyMceSettings(false)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            console.log(err.response.data.errors)
            const tinyMceApiKey_error = err.response.data.errors && err.response.data.errors['api_key']
            this.errors = {
              tinyMceApiKey: tinyMceApiKey_error
            }
            this.$vs.notify({
              title: 'Eroare!',
              text: 'Nu toate campurile obligatorii au fost completate.',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'A aparut o eroare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
        })
        .finally(() => {
          this.loadingSave = false
        })
    },
    updateGtmSettings() {
      this.type = 'gtmId';
      const gtm_id = {gtm_id: this.gtmId}
      this.loadingSave = true
      this.$http.post(`clients/franchisees/${this.franchiseeId}/gtmSettings`, gtm_id)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Updated Gtm Id!',
            color: 'success'
          })
          this.loadGtmSettings(false)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            console.log(err.response.data.errors)
            const gtmId_error = err.response.data.errors && err.response.data.errors['gtm_id']
            this.errors = {
              gtmId: gtmId_error
            }
            this.$vs.notify({
              title: 'Eroare!',
              text: 'Nu toate campurile obligatorii au fost completate.',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'A aparut o eroare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
        })
        .finally(() => {
          this.loadingSave = false
        })
    },
    updatePaymentSettings() {
      this.type = 'cashOnDelivery';
      const cashOnDelivery = {cashOnDelivery: this.cashOnDelivery}
      this.loadingSave = true
      this.$http.post(`clients/franchisees/${this.franchiseeId}/paymentSettings`, cashOnDelivery)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Updated Payment Settings!',
            color: 'success'
          })
          this.loadPaymentSettings(false)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            console.log(err.response.data.errors)
            const cashOnDelivery_error = err.response.data.errors && err.response.data.errors['cashOnDelivery']
            this.errors = {
              cashOnDelivery: cashOnDelivery_error
            }
            this.$vs.notify({
              title: 'Eroare!',
              text: 'Nu toate campurile obligatorii au fost completate.',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'A aparut o eroare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
        })
        .finally(() => {
          this.loadingSave = false
        })
    },
    updateUseSocialLoginsSettings() {
      this.type = 'useSocialLogins';
      const useSocialLogins = {useSocialLogins: this.useSocialLogins}
      this.loadingSave = true
      this.$http.post(`clients/franchisees/${this.franchiseeId}/useSocialLogins`, useSocialLogins)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Updated Social Logins!',
            color: 'success'
          })
          this.loadUseSocialLogins(false)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            console.log(err.response.data.errors)
            const useSocialLogins_error = err.response.data.errors && err.response.data.errors['useSocialLogins']
            this.errors = {
              useSocialLogins: useSocialLogins_error
            }
            this.$vs.notify({
              title: 'Eroare!',
              text: 'Nu toate campurile obligatorii au fost completate.',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'A aparut o eroare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
        })
        .finally(() => {
          this.loadingSave = false
        })
    }       
  },
  created() {
    this.loadItems(true)
  }

}
</script>