<template>
  <v-sheet>
    <v-row class="mb-4 mt-5" >
      <!-- <v-col cols="6">
        <customer-personal-information ref="personalInfo"/>
      </v-col> -->
      <loader :loading="loadingTheme" colors="rgba(255, 255, 255, 0.90)" loader-color="#f0483d" size="fa-6x"></loader>
      <v-col cols="12">
        <div class="d-flex align-center" v-if="$store.getters['auth/currentUser'].franchisee_id">
          <h4 class=" mr-3">Foloseste tema</h4>
          <div v-for="(item, index) in themes" :key="item.id">
            <v-btn :color="item.name === 'oranj' ? 'orange' : item.name === 'albastru' ? 'blue' : 'primary'"
              class="primary-button mr-4" :key="index" @click="openDialog(item.id)">
              {{ item.name }}
            </v-btn>
          </div>
        </div>
        <franchisee-company-information ref="companyInfo" />
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="mt-4">
      <v-col>
        <v-btn :disabled="loading" :loading="loading" color="success" class="primary-button"
          @click="save">Salveaza</v-btn>
      </v-col>
    </v-row>

    <span>
      <v-dialog v-model="dialogDelete" max-width="350px" persistent>
        <v-card>
          <v-card-title class="text-center">
            Sigur doriți să aplicați această temă?
          </v-card-title>

          <v-card-actions>
            <v-btn text @click="cancel">Anuleaza</v-btn>
            <v-spacer />
            <v-btn color="primary" class="primary-button" @click="setTheme(themeId)"
              :loading="loadingSaveTheme">Salveaza</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </span>

  </v-sheet>
</template>

<script>
import $axios from '@/axios'
import axios from '@/axios'
let baseURL = process.env.VUE_APP_BASE_URL_API
baseURL = `${baseURL}/public`
import Loader from '@/components/Loader'
import FranchiseeCompanyInformation from '@/views/SingleFranchisee/components/FranchiseeCompanyInformation'
export default {
  components: {
    FranchiseeCompanyInformation,
    Loader
  },
  props: {
    franchisee: {
      required: true
    }
  },
  data() {
    return {
      loading: false,
      loadingTheme: false,
      loadingSaveTheme: false,
      themeId: '',
      themes: [],
      dialogDelete: false,
    }
  },
  methods: {
    async save() {
      this.loading = true
      try {
        await this.$refs.companyInfo.save()
        await this.$store.dispatch('singleFranchisee/load', this.franchisee.id)
        this.loading = false
        this.$vs.notify({
          title: 'Succes!',
          text: 'Clientul a fost salvat cu succes.',
          color: 'success'
        })
      } catch (e) {
        this.$vs.notify({
          title: 'Eroare',
          text: 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
          color: 'danger'
        })
        this.loading = false
      }
    },
    loadThemes() {
      this.loadingTheme = true
      $axios
        .get('/clients/franchisees/getFranchiseeThemes')
        .then(response => {
          if (response.data) {
            this.themes = response.data
          }
          this.loadingTheme = false
        })
    },
    setTheme(themeId) {
      this.themeId = themeId
      this.loadingSaveTheme = true
      this.$http.post('/clients/franchisees/' + this.franchisee.id + '/setTheme?themeId=' + themeId)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Theme updated!',
            color: 'success'
          })
          this.dialogDelete = false
          this.getCustomBranding()
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'Error!',
            color: 'danger'
          })
        })
        .finally(() => {
          this.loadingSaveTheme = false
        })
    },
    openDialog(themeId) {
      this.themeId = themeId
      this.dialogDelete = true
    },
    cancel() {
      this.themeId = ''
      this.dialogDelete = false
    },
    getCustomBranding() {
      axios
        .get(baseURL + '/franchisees/getCustomBrandingCssbyDomainForAdmin')
        .then(response => {
          if (response.data) {
            var styleElement = document.createElement('style');
            styleElement.appendChild(document.createTextNode(response.data));
            document.getElementsByTagName('head')[0].appendChild(styleElement);
          }
        })
    }
  },
  created() {
    this.loadThemes()
  }
}
</script>
