
<template>
  <div id="branding" class="  items-center justify-center p-5">
    <div class="p-4">
      <loader :loading="loading" colors="rgba(255, 255, 255, 0.90)" loader-color="#f0483d" size="fa-6x"></loader>
      <div class="row mt-5">
        <div class="col-sm-12 col-md-3 col-lg-3 d-flex  justify-center">
          <ColorPicker :width="200" :height="200" :disabled="selectedValue ? false : true" :startColor="colorValue"
            v-model="colorValue" @colorChange="onColorChange">
          </ColorPicker>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4">

          <!-- General -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="General font and Background">
                <Input :textColorLabel="'Font Color'" :colourScheme="brandingData.generalFontColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="generalFontColor" />

                <Input :textColorLabel="'Body Background'" :colourScheme="brandingData.bodyBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="bodyBackground" />

                <Input :textColorLabel="'Primary Color'" :colourScheme="brandingData.primaryColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="primaryColor" />

                <Input :textColorLabel="'Secondary Color'" :colourScheme="brandingData.secondaryColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="secondaryColor" />
              </Accordion>
            </div>
          </div>

          <!-- Sidebar Menu -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="Sidebar Menu">
                <Input :textColorLabel="'Toggler Color'" :colourScheme="brandingData.sideBarTogglerColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sideBarTogglerColor" />

                <Input :textColorLabel="'Item   Background'"
                  :colourScheme="brandingData.sideBarItemRouterLinkBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sideBarItemRouterLinkBackground" />

                <Input :textColorLabel="'Item Icon Color'" :colourScheme="brandingData.sideBarItemIconColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sideBarItemIconColor" />

                <Input :textColorLabel="' Item  Font Color'"
                  :colourScheme="brandingData.sideBarItemRouterLinkItemFontColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sideBarItemRouterLinkItemFontColor" />

                <Input :textColorLabel="'  Active Item  Background'"
                  :colourScheme="brandingData.sideBarItemActiveRouterLinkBackGround"
                  @selectedValueColorChange="selectedValueColorChange"
                  colorValue="sideBarItemActiveRouterLinkBackGround" />

                <Input :textColorLabel="' Active Item Icon  Color'" :colourScheme="brandingData.sideBarActiveIconFontColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sideBarActiveIconFontColor" />

                <Input :textColorLabel="' Active Item Font Color'" :colourScheme="brandingData.sideBarActiveItemFontColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sideBarActiveItemFontColor" />

                <Input :textColorLabel="' Active Group Background'"
                  :colourScheme="brandingData.sideBarActiveGroupBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sideBarActiveGroupBackground" />

                <Input :textColorLabel="' Active Group Border'" :colourScheme="brandingData.sideBarActiveGroupBorder"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sideBarActiveGroupBorder" />

                <Input :textColorLabel="' Active Group Icon Color'" :colourScheme="brandingData.sideBarActiveGroupColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sideBarActiveGroupColor" />

                <Input :textColorLabel="' Active Group Font Color'"
                  :colourScheme="brandingData.sideBarActiveGroupFontColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sideBarActiveGroupFontColor" />
              </Accordion>
            </div>
          </div>

          <!-- Bread Crumb & Pagination Active Button Background -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="Bread Crumb & Pagination Active Button Background">
                <Input :textColorLabel="'Bread Crumb Icon Color'" :colourScheme="brandingData.breadCrumbIconColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="breadCrumbIconColor" />

                <Input :textColorLabel="'Router Link Active'" :colourScheme="brandingData.breadCrumbRouterLinkActive"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="breadCrumbRouterLinkActive" />

                <Input :textColorLabel="'Pagination Active Item Background'"
                  :colourScheme="brandingData.paginationActiveItemBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="paginationActiveItemBackground" />

              </Accordion>
            </div>
          </div>

          <!-- Primary Button -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="Primary Button">
                <Input :textColorLabel="' Button Font Color'" :colourScheme="brandingData.primaryButtonColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="primaryButtonColor" />

                <Input :textColorLabel="' Button Background'" :colourScheme="brandingData.primaryButtonBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="primaryButtonBackground" />

                <Input :textColorLabel="' Button Border Color'" :colourScheme="brandingData.primaryButtonBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="primaryButtonBorderColor" />

                <Input :textColorLabel="' Button Font Hover Color'" :colourScheme="brandingData.primaryButtonHoverColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="primaryButtonHoverColor" />

                <Input :textColorLabel="' Button Hover Background'"
                  :colourScheme="brandingData.primaryButtonHoverBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="primaryButtonHoverBackground" />
              </Accordion>
            </div>
          </div>

          <!-- Secondary Button -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="Secondary Button">
                <Input :textColorLabel="' Button Font Color'" :colourScheme="brandingData.secondaryButtonColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="secondaryButtonColor" />

                <Input :textColorLabel="' Button Background'" :colourScheme="brandingData.secondaryButtonBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="secondaryButtonBackground" />

                <Input :textColorLabel="' Button Border Color'" :colourScheme="brandingData.secondaryButtonBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="secondaryButtonBorderColor" />

                <Input :textColorLabel="' Button Font Hover Color'" :colourScheme="brandingData.secondaryButtonHoverColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="secondaryButtonHoverColor" />

                <Input :textColorLabel="' Button Hover Background'"
                  :colourScheme="brandingData.secondaryButtonHoverBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="secondaryButtonHoverBackground" />

              </Accordion>
            </div>
          </div>


          <!-- Logo & Favicon -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="Logo & Favicon">
                <div class="pr-4 pl-4 pb-4">
                  <div class="row ">
                    <div class="logo col-sm-12  col-lg-12">
                      <p class="branding-color-text mb-3">Logo URL (Logo height between 130px to 140 px)</p>
                      <!-- <input ref="LogoRef" type="file" hidden accept="image/png,image/jpeg" @change="changeLogoImage" /> -->
                      <input class="image-url-input-field   width-100" id="logofile_admin_url" type="text"
                        :value="logofile_admin_url"
                        style="opacity: 1; background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);"
                        @change="changeLogoImage" />
                      <div class="mb-4 mt-5">
                        <div v-if="valid_logofile_admin_url" style="position: relative;">
                          <div class="close-icon" @click="removeImg('logo')">
                            <svg width="22" height="22" viewBox="0 0 20 20" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M10 1.25C5.125 1.25 1.25 5.125 1.25 10C1.25 14.875 5.125 18.75 10 18.75C14.875 18.75 18.75 14.875 18.75 10C18.75 5.125 14.875 1.25 10 1.25ZM13.375 14.375L10 11L6.625 14.375L5.625 13.375L9 10L5.625 6.625L6.625 5.625L10 9L13.375 5.625L14.375 6.625L11 10L14.375 13.375L13.375 14.375Z"
                                fill="#DA5757" />
                            </svg>
                          </div>
                          <img class="banner-img" :src="logofile_admin_url" alt="logo image" height="107px"
                            width="107px" />
                        </div>
                        <div v-else className="banner-skeleton" style="background:#E2E2EA;width:107px;height:107px">
                          <div style="text-align: center;padding: 30px">
                            <img :src="require('@/assets/images/logo/card_image.png')" alt="logo image" height="49"
                              width="49" />
                          </div>
                        </div>
                      </div>
                      <v-btn color="primary" class="primary-button"
                        :loading="'logofile_admin_url' === fileType ? loadingLogo : false"
                        @click="updateLogoUrlsFavIcon('logofile_admin_url', logofile_admin_url)"
                        :disabled="!valid_logofile_admin_url">
                        Încărcați
                      </v-btn>
                    </div>
                    <div class="favicon  col-sm-12  col-lg-12 mt-4">
                      <div>
                        <p class="branding-color-text mb-3">Favicon URL (Icon height between 130px to 140 px)</p>
                        <input class="image-url-input-field   width-100" id="fevicon_admin_url" type="text"
                          :value="fevicon_admin_url"
                          style="opacity: 1; background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);"
                          @change="changeFaviconImage" />
                        <div class="mb-4 mt-5">
                          <div v-if="valid_fevicon_admin_url" style="position: relative;">
                            <div class="close-icon" @click="removeImg('favicon')">
                              <svg width="22" height="22" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M10 1.25C5.125 1.25 1.25 5.125 1.25 10C1.25 14.875 5.125 18.75 10 18.75C14.875 18.75 18.75 14.875 18.75 10C18.75 5.125 14.875 1.25 10 1.25ZM13.375 14.375L10 11L6.625 14.375L5.625 13.375L9 10L5.625 6.625L6.625 5.625L10 9L13.375 5.625L14.375 6.625L11 10L14.375 13.375L13.375 14.375Z"
                                  fill="#DA5757" />
                              </svg>
                            </div>
                            <img class="banner-img" :src="fevicon_admin_url" alt="Fav icon image" height="107px"
                              width="107px" />
                          </div>
                          <div v-else className="banner-skeleton" style="background:#E2E2EA;width:107px;height:107px">
                            <div style="text-align: center;padding: 30px">
                              <img :src="require('@/assets/images/logo/card_image.png')" alt="Fav icon image" height="49"
                                width="49" />
                            </div>
                          </div>
                        </div>
                        <v-btn color="primary" class="primary-button"
                          :loading="'fevicon_admin_url' === fileType ? loadingLogo : false"
                          @click="updateLogoUrlsFavIcon('fevicon_admin_url', fevicon_admin_url)"
                          :disabled="!valid_fevicon_admin_url">
                          Încărcați
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion>
            </div>
          </div>


        </div>
        <div class="col-sm-12 col-md-4 col-lg-4"></div>


        <div class="col-sm-12 col-md-4 col-lg-4 "> <v-btn color="primary" class="primary-button" :loading="loadingSave"
            @click="updateBranding">
            Salveaza
          </v-btn></div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'

let baseURL = process.env.VUE_APP_BASE_URL_API
baseURL = `${baseURL}/public`
import Loader from '@/components/Loader'
import Accordion from './Accordion';
import Input from './Input';
import ColorPicker from 'vue-color-picker-wheel';
export default {
  name: 'AdminBranding',
  components: {
    Accordion, ColorPicker, Input,
    Loader
  },
  data() {
    return {
      logofile_admin_url: "",
      valid_logofile_admin_url: false,
      fevicon_admin_url: '',
      valid_fevicon_admin_url: false,
      fileType: '',
      colorValue: '#ff0000',
      loading: false,
      loadingSave: false,
      loadingLogo: false,
      selectedValue: '',
      brandingData: {
        //General
        generalFontColor: '',
        bodyBackground: '',
        primaryColor: '',
        secondaryColor: '',

        //Primary Button
        primaryButtonColor: '',
        primaryButtonBackground: '',
        primaryButtonBorderColor: '',
        primaryButtonHoverColor: '',
        primaryButtonHoverBackground: '',

        //Secondary Button
        secondaryButtonColor: '',
        secondaryButtonBackground: '',
        secondaryButtonBorderColor: '',
        secondaryButtonHoverColor: '',
        secondaryButtonHoverBackground: '',

        //Bread Crumb
        breadCrumbIconColor: '',
        breadCrumbRouterLinkActive: '',

        //Sidebar Toggler
        sideBarTogglerColor: '',


        sideBarItemRouterLinkBackground: '',

        sideBarItemIconColor: '',

        sideBarItemRouterLinkItemFontColor: '',

        sideBarItemActiveRouterLinkBackGround: '',

        sideBarActiveIconFontColor: '',

        sideBarActiveItemFontColor: '',

        sideBarActiveGroupBackground: '',

        sideBarActiveGroupBorder: '',

        sideBarActiveGroupColor: '',

        sideBarActiveGroupFontColor: '',



        //Pagination Active Background
        paginationActiveItemBackground: '',

      },
    }
  },
  computed: {
    items() {
      return this.brandingData
    }
  },
  props: {
    franchiseeId: {
      required: true
    }
  },
  methods: {
    onColorChange(color) {
      this.colorValue = color
      if (this.selectedValue && this.brandingData && Object.keys(this.brandingData).length > 0) {
        this.brandingData[this.selectedValue] = color
      }
      // this.selectedValue = ''
    },
    selectedValueColorChange(value, selectedValue) {
      this.colorValue = value
      this.selectedValue = selectedValue
    },
    getCustomBrandingCssbyDomainForAdmin(){
      axios
      .get(baseURL + '/franchisees/getCustomBrandingCssbyDomainForAdmin')
      .then(response => {
        if (response.data) {
          var styleElement = document.createElement('style');
          styleElement.appendChild(document.createTextNode(response.data));
          document.getElementsByTagName('head')[0].appendChild(styleElement);
        }
      })
    },
    loadItems(isLoading) {
      if (isLoading)
        this.loading = true
      axios
        .get(baseURL + '/franchisees/getSchemaStructureForAdminBranding/' + this.franchiseeId)
        .then(response => {
          if (response.data) {
            this.brandingData = response.data
          }
          this.loading = false
        })
    },
    getLogoUrlsForAdmin(update) {
      axios
        .get(baseURL + '/franchisees/getLogoUrlsForAdmin')
        .then(response => {
          if (response.data) {
            this.logofile_admin_url = response.data.logofile_admin_url
            if (response.data.logofile_admin_url) {
              this.valid_logofile_admin_url = true
            }
            this.fevicon_admin_url = response.data.fevicon_admin_url
            if (response.data.fevicon_admin_url) {
              this.valid_fevicon_admin_url = true
            }
            if(update){
            let favicon = document.querySelector('link[rel="icon"]')
            if (!favicon) {
              favicon = document.createElement('link')
              favicon.setAttribute('rel', 'icon')
              favicon.setAttribute('type', 'image/png')
              document.head.appendChild(favicon)
            }
            response.data.fevicon_admin_url && favicon.setAttribute('href', response.data.fevicon_admin_url)
            let logoImage = document.querySelector('img[logo_attribute="logo"]')
            if (!logoImage) {
              logoImage = document.createElement('link')
              logoImage.setAttribute('rel', 'icon')
              logoImage.setAttribute('type', 'image/png')
              document.head.appendChild(logoImage)
            }
            response.data.logofile_admin_url && logoImage.setAttribute('src', response.data.logofile_admin_url)
          }
          }
        })
    },
    updateBranding() {
      this.loadingSave = true
      this.$http.post('/clients/franchisees/' + this.franchiseeId + '/updateAdminBranding', this.brandingData)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Update branding!',
            color: 'success'
          })
          this.loadItems(false)
          this.getCustomBrandingCssbyDomainForAdmin()
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'Error!',
            color: 'danger'
          })
        })
        .finally(() => {
          this.loadingSave = false
        })
    },
    updateLogoUrlsFavIcon(fileType, url) {
      this.fileType = fileType
      this.loadingLogo = true
      const data = {
        fileUrl: url,
        fileType: fileType
      }
      this.$http.patch('/clients/franchisees/' + this.franchiseeId + '/updateLogoUrlsForAdmin', data)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Update branding!',
            color: 'success'
          })
          this.getLogoUrlsForAdmin(true)
          this.fileType = ''
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'Error!',
            color: 'danger'
          })
          this.fileType = ''
        })
        .finally(() => {
          this.loadingLogo = false
        })
    },
    changeLogoImage(e) {
      let extension =
        e.target.value &&
        e.target.value.substring(e.target.value.lastIndexOf('.') + 1);
      if (
        extension === 'png' ||
        extension === 'PNG' ||
        extension === 'jpg' ||
        extension === 'JPG' ||
        extension === 'jpeg' ||
        extension === 'JPEG'
      ) {
        this.logofile_admin_url = e.target.value
        this.valid_logofile_admin_url = true
      } else {
        this.$vs.notify({
          title: 'Eroare!',
          text: 'Please enter valid URL!',
          color: 'danger'
        })
        this.logofile_admin_url = e.target.value
        this.valid_logofile_admin_url = false
      }
    },
    changeFaviconImage(e) {
      let extension =
        e.target.value &&
        e.target.value.substring(e.target.value.lastIndexOf('.') + 1);
      if (
        extension === 'png' ||
        extension === 'PNG' ||
        extension === 'jpg' ||
        extension === 'JPG' ||
        extension === 'jpeg' ||
        extension === 'JPEG'
      ) {
        this.fevicon_admin_url = e.target.value
        this.valid_fevicon_admin_url = true
      } else {
        this.$vs.notify({
          title: 'Eroare!',
          text: 'Please enter valid URL!',
          color: 'danger'
        })
        this.fevicon_admin_url = e.target.value
        this.valid_fevicon_admin_url = false
      }
    },
    removeImg(checkLogoOrFavicon) {
      if (checkLogoOrFavicon === 'logo') {
        this.logofile_admin_url = ""
        this.valid_logofile_admin_url = false
      }
      if (checkLogoOrFavicon === 'favicon') {
        this.fevicon_admin_url = ''
        this.valid_fevicon_admin_url = false
      }
    },
  },
  created() {
    this.getLogoUrlsForAdmin(false)
    this.loadItems(true)
  }
}
</script>


<style lang="scss">
.accordion-section {
  display: block;
  width: 100%;
  // margin: 15px auto;
  margin: 0px 0px 25px 0px;
  background: #D9DFE8;
  border-radius: 4px;
}

.accordion-section .branding-header-section {
  position: relative;
  background: #D9DFE8;
  border-radius: 4px;
  transition: all 0.2s linear;
  padding: 15px;
  cursor: pointer;
}

.branding-header-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.576667px;
  color: #2F3842;
}

.accordion-section .branding-header-section::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%) rotate(0deg);
  width: 15px;
  height: 15px;
  background-image: url('./arrow-down-mint.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.2s linear;
  margin-right: 15px;
}

.accordion-section.open .branding-header-section {
  margin-bottom: 15px;
}

.accordion-section.open .branding-header-section::after {
  transform: translateY(-50%) rotate(180deg);
}

.accordion-section .branding-body-section {
  color: #3c3c3c;
  font-size: 18px;
  opacity: 0;
  max-height: 0px;
  // overflow-y: hidden;
  transition: all 0.2s ease-out;
  background: #EDF0F5;
  border-radius: 0px 0px 4px 4px;
}

.accordion-section.open .branding-body-section {
  opacity: 1;
  // max-height: 1000px;
  max-height: initial;
}

.branding-color-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.576667px;
  color: #2F3842;
  margin: 0 !important;
}

.color-input-field {
  width: 85px;
  height: 40px;
  float: left;
  clear: none;
  padding: 0 !important;
  text-align: center;
  left: 0 !important;
  border: 1px solid #d1d1d1;
  font-family: monospace;
}

.width-100 {
  width: 100%;
}

.color-input-field {
  height: 34px;
  float: left;
  clear: left;
  padding: 0 10px;
  border: 1px solid #e5e5e5;
  -ms-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none !important;
  -ms-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 4px;
  // background-color: #edf0f5;
  background-color: #fff;
}

.image-url-input-field {
  height: 40px;
  border: 1px solid #d1d1d1;
  padding: 0 10px;
  -ms-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none !important;
  -ms-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 4px;
  background-color: #fff;
}

.close-icon {
  position: absolute;
  left: 96px;
  top: -10px;
  cursor: pointer;
}
</style>

