<template>
  <v-sheet>
    <template slot="progress">

      <loader :loading="loading" colors="rgba(255, 255, 255, 0.90)" loader-color="#f0483d" size="fa-6x"></loader>

    </template>
    <v-card class="p-2" flat>
      <v-row class="mb-4 mt-5">

        <v-col cols="6">
          <v-text-field v-model="invoiceDetails.company_name" label="Nume companie" />
          <error-messages :errors="errors.company_name" />
        </v-col>

        <v-col cols="6">
          <v-text-field v-model="invoiceDetails.register_order_number" label="Nr. ord. registru com./an" />
          <error-messages :errors="errors.register_order_number" />
        </v-col>

        <v-col cols="6">
          <v-text-field v-model="invoiceDetails.tax_registration_number" label="Nr. de inregistrare fiscala" />
          <error-messages :errors="errors.tax_registration_number" />
        </v-col>

        <v-col cols="6">
          <v-text-field v-model="invoiceDetails.company_address" label="Sediul" />
          <error-messages :errors="errors.company_address" />
        </v-col>

        <v-col cols="6">
          <v-text-field v-model="invoiceDetails.county" label="Judetul" />
          <error-messages :errors="errors.county" />
        </v-col>

        <v-col cols="6">
          <v-text-field v-model="invoiceDetails.bank_account" label="Contul" />
          <error-messages :errors="errors.bank_account" />
        </v-col>

        <v-col cols="6">
          <v-text-field v-model="invoiceDetails.bank_name" label="Banca" />
          <error-messages :errors="errors.bank_name" />
        </v-col>

        <v-col cols="6">
          <v-text-field v-model="invoiceDetails.phone" label="Telefon" />
          <error-messages :errors="errors.phone" />
        </v-col>

        <v-col cols="6">
          <v-text-field v-model="invoiceDetails.fax" label="Fax" />
          <error-messages :errors="errors.fax" />
        </v-col>

        <v-col cols="6">
          <v-text-field v-model="invoiceDetails.website" label="Website" />
          <error-messages :errors="errors.website" />
        </v-col>

      </v-row>
      <v-divider />
      <v-row class="mt-4">
        <v-col>
          <v-btn :disabled="loadingSave" :loading="loadingSave" color="success" class="primary-button"
            @click="updateEmailServerSettings">Salveaza</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-sheet>
</template>

<script>
import $axios from '@/axios'
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  name: 'InvoiceSettings',
  components: { ErrorMessages },
  data() {
    return {
      errors: {},
      invoiceDetails: {
        id: 0,
        franchisee_id: 0,
        company_name: "",        
        register_order_number: "",
        tax_registration_number: "",
        company_address: "",
        county: "",
        bank_account: "",
        bank_name: "",
        phone: "",
        fax: "",
        website: "",
        created_at: "",
        updated_at: "",
        deleted_at: null

      },
      loading: false,
      loadingSave: false,
    }
  },
  props: {
    franchiseeId: {
      required: true
    }
  },
  methods: {
    loadItems(isLoading) {
      if (isLoading)
        this.loading = true
      $axios
        .get(`clients/franchisees/${this.franchiseeId}/invoiceDetails`)
        .then(response => {
          if (response.data) {
            console.log(response.data)
            this.invoiceDetails = response.data
          }
          this.loading = false
        })
    },
    updateEmailServerSettings() {
      this.loadingSave = true
      this.$http.post('/clients/franchisees/' + this.franchiseeId + '/updateInvoiceDetails', this.invoiceDetails)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Updated invoice setting!',
            color: 'success'
          })
          this.loadItems(false)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            console.log(err.response.data.errors)
            this.errors = err.response.data.errors
            this.$vs.notify({
              title: 'Eroare!',
              text: 'Nu toate campurile obligatorii au fost completate.',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'A aparut o eroare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
        })
        .finally(() => {
          this.loadingSave = false
        })
    }
  },
  created() {
    this.loadItems(true)
  }

}
</script>