
<template>
  <div id="branding" class="  items-center justify-center p-5">
    <div class="p-4">
      <loader :loading="loading" colors="rgba(255, 255, 255, 0.90)" loader-color="#f0483d" size="fa-6x"></loader>
      <div class="row mt-5">
        <div class="col-sm-12 col-md-3 col-lg-3 d-flex  justify-center">
          <ColorPicker :width="200" :height="200" :disabled="selectedValue ? false : true" :startColor="colorValue"
            v-model="colorValue" @colorChange="onColorChange">
          </ColorPicker>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4">

          <!-- General -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="General font and Background">
                <Input :textColorLabel="'Font Color'" :colourScheme="brandingData.generalFontColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="generalFontColor" />

                <Input :textColorLabel="'Body Background'" :colourScheme="brandingData.bodyBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="bodyBackground" />

                <Input :textColorLabel="'Primary Color'" :colourScheme="brandingData.primaryColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="primaryColor" />

                <Input :textColorLabel="'Secondary Color'" :colourScheme="brandingData.secondaryColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="secondaryColor" />

                <Input :textColorLabel="'Primary Background Color'" :colourScheme="brandingData.primaryBackgroundColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="primaryBackgroundColor" />
                <Input :textColorLabel="'Secondary Background Color'"
                  :colourScheme="brandingData.secondaryBackgroundColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="secondaryBackgroundColor" />

                <Input :textColorLabel="'Primary Hover Color'" :colourScheme="brandingData.primaryHoverColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="primaryHoverColor" />
                <Input :textColorLabel="'Primary Hover Background Color'"
                  :colourScheme="brandingData.primaryHoverBackgroundColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="primaryHoverBackgroundColor" />

                <Input :textColorLabel="'Secondary Hover Color'" :colourScheme="brandingData.secondaryHoverColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="secondaryHoverColor" />
                <Input :textColorLabel="'Secondary Hover Background Color'"
                  :colourScheme="brandingData.secondaryHoverBackgroundColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="secondaryHoverBackgroundColor" />

              </Accordion>
            </div>
          </div>

          <!-- Primary Button -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="Primary Button">
                <Input :textColorLabel="' Button Color'" :colourScheme="brandingData.primaryButtonColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="primaryButtonColor" />

                <Input :textColorLabel="' Button Background'" :colourScheme="brandingData.primaryButtonBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="primaryButtonBackground" />

                <Input :textColorLabel="' Button Border Color'" :colourScheme="brandingData.primaryButtonBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="primaryButtonBorderColor" />

                <Input :textColorLabel="' Button Hover Color'" :colourScheme="brandingData.primaryButtonHoverColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="primaryButtonHoverColor" />

                <Input :textColorLabel="' Button Hover Background'"
                  :colourScheme="brandingData.primaryButtonHoverBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="primaryButtonHoverBackground" />

                <Input :textColorLabel="' Button Hover Border Color'"
                  :colourScheme="brandingData.primaryButtonHoverBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="primaryButtonHoverBorderColor" />
              </Accordion>
            </div>
          </div>

          <!-- Secondary Button -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="Secondary Button">
                <Input :textColorLabel="' Button Color'" :colourScheme="brandingData.secondaryButtonColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="secondaryButtonColor" />

                <Input :textColorLabel="' Button Background'" :colourScheme="brandingData.secondaryButtonBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="secondaryButtonBackground" />

                <Input :textColorLabel="' Button Border Color'" :colourScheme="brandingData.secondaryButtonBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="secondaryButtonBorderColor" />

                <Input :textColorLabel="' Button Hover Color'" :colourScheme="brandingData.secondaryButtonHoverColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="secondaryButtonHoverColor" />

                <Input :textColorLabel="' Button Hover Background'"
                  :colourScheme="brandingData.secondaryButtonHoverBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="secondaryButtonHoverBackground" />

                <Input :textColorLabel="' Button Hover Border Color'"
                  :colourScheme="brandingData.secondaryButtonHoverBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="secondaryButtonHoverBorderColor" />
              </Accordion>
            </div>
          </div>

          <!-- Third Button -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="Third Button">
                <Input :textColorLabel="' Button Color'" :colourScheme="brandingData.thirdButtonColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="thirdButtonColor" />

                <Input :textColorLabel="' Button Background'" :colourScheme="brandingData.thirdButtonBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="thirdButtonBackground" />

                <Input :textColorLabel="' Button Border Color'" :colourScheme="brandingData.thirdButtonBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="thirdButtonBorderColor" />

                <Input :textColorLabel="' Button Hover Color'" :colourScheme="brandingData.thirdButtonHoverColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="thirdButtonHoverColor" />

                <Input :textColorLabel="' Button Hover Background'"
                  :colourScheme="brandingData.thirdButtonHoverBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="thirdButtonHoverBackground" />

                <Input :textColorLabel="' Button Hover Border Color'"
                  :colourScheme="brandingData.thirdButtonHoverBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="thirdButtonHoverBorderColor" />
              </Accordion>
            </div>
          </div>

          <!-- Header -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="Header">
                <Input :textColorLabel="' Background'" :colourScheme="brandingData.headerBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="headerBackground" />

                <Input :textColorLabel="' Font Color'" :colourScheme="brandingData.headerFontColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="headerFontColor" />

                <Input :textColorLabel="'Input Color'" :colourScheme="brandingData.headerInputColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="headerInputColor" />

                <Input :textColorLabel="' Input Background'" :colourScheme="brandingData.headerInputBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="headerInputBackground" />

                <Input :textColorLabel="' Input Border Color'" :colourScheme="brandingData.headerInputBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="headerInputBorderColor" />

                <Input :textColorLabel="' Button Color'" :colourScheme="brandingData.headerButtonColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="headerButtonColor" />

                <Input :textColorLabel="' Button Background'" :colourScheme="brandingData.headerButtonBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="headerButtonBackground" />

                <Input :textColorLabel="'  Button Border Color'" :colourScheme="brandingData.headerButtonBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="headerButtonBorderColor" />

                <Input :textColorLabel="'  Button Hover Color'" :colourScheme="brandingData.headerButtonHoverColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="headerButtonHoverColor" />

                <Input :textColorLabel="'  Button Hover Background'"
                  :colourScheme="brandingData.headerButtonHoverBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="headerButtonHoverBackground" />

                <Input :textColorLabel="' Button Hover Border Color'"
                  :colourScheme="brandingData.headerButtonHoverBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="headerButtonHoverBorderColor" />

                <Input :textColorLabel="'  Icon Color'" :colourScheme="brandingData.headerIconColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="headerIconColor" />

                <Input :textColorLabel="'Icon Hover Color'" :colourScheme="brandingData.headerIconHoverColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="headerIconHoverColor" />
              </Accordion>
            </div>
          </div>

          <!-- Menu -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="Menu">
                <Input :textColorLabel="'Background'" :colourScheme="brandingData.menuBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="menuBackground" />

                <Input :textColorLabel="'Item Background'" :colourScheme="brandingData.menuItemBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="menuItemBackground" />

                <Input :textColorLabel="'Item Color'" :colourScheme="brandingData.menuItemColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="menuItemColor" />

                <Input :textColorLabel="'Sub Menu Background'" :colourScheme="brandingData.subMenuBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="subMenuBackground" />

                <Input :textColorLabel="'Sub Menu ItemC olor'" :colourScheme="brandingData.subMenuItemColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="subMenuItemColor" />

                <Input :textColorLabel="'Sub Menu Item Background'" :colourScheme="brandingData.subMenuItemBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="subMenuItemBackground" />

                <Input :textColorLabel="'Sub Menu Item Hover Color'" :colourScheme="brandingData.subMenuItemHoverColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="subMenuItemHoverColor" />

                <Input :textColorLabel="'Sub Menu Item Hover Background'"
                  :colourScheme="brandingData.subMenuItemHoverBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="subMenuItemHoverBackground" />

              </Accordion>
            </div>
          </div>


          <!-- Slider Home -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="Slider Home">
                <Input :textColorLabel="'  Dots Border Color'" :colourScheme="brandingData.sliderDotsBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sliderDotsBorderColor" />

                <Input :textColorLabel="' Dots Background'" :colourScheme="brandingData.sliderDotsBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sliderDotsBackground" />

                <Input :textColorLabel="' Dots Hover Background'" :colourScheme="brandingData.sliderDotsHoverBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sliderDotsHoverBackground" />

                <Input :textColorLabel="'Dots Hover Border Color'" :colourScheme="brandingData.sliderDotsHoverBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sliderDotsHoverBorderColor" />

                <Input :textColorLabel="'Dots Active Border Color'"
                  :colourScheme="brandingData.sliderDotsActiveBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sliderDotsActiveBorderColor" />

                <Input :textColorLabel="'Dots Active Background'" :colourScheme="brandingData.sliderDotsActiveBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sliderDotsActiveBackground" />

                <Input :textColorLabel="'Button Background'" :colourScheme="brandingData.sliderButtonBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sliderButtonBackground" />

                <Input :textColorLabel="'Button Color'" :colourScheme="brandingData.sliderButtonColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sliderButtonColor" />

                <Input :textColorLabel="'Button Border Color'" :colourScheme="brandingData.sliderButtonBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sliderButtonBorderColor" />

                <Input :textColorLabel="'Button Hover Background'"
                  :colourScheme="brandingData.sliderButtonHoverBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sliderButtonHoverBackground" />

                <Input :textColorLabel="'Button Hover Color'" :colourScheme="brandingData.sliderButtonHoverColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sliderButtonHoverColor" />

                <Input :textColorLabel="'Button Hover Border Color'"
                  :colourScheme="brandingData.sliderButtonHoverBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sliderButtonHoverBorderColor" />

                <Input :textColorLabel="'Content Color'" :colourScheme="brandingData.sliderContentColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sliderContentColor" />

                <Input :textColorLabel="'Discount Info Color'" :colourScheme="brandingData.sliderDicountInfoColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sliderDicountInfoColor" />

                <Input :textColorLabel="'Discount Info Background'"
                  :colourScheme="brandingData.sliderDicountInfoBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sliderDicountInfoBackground" />

                <Input :textColorLabel="'Discount Info Border Color'"
                  :colourScheme="brandingData.sliderDicountInfoBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sliderDicountInfoBorderColor" />
              </Accordion>
            </div>
          </div>

          <!-- Section Register -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="Section Register">
                <Input :textColorLabel="'Background'" :colourScheme="brandingData.sectionRegisterBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sectionRegisterBackground" />

                <Input :textColorLabel="'Border Color'" :colourScheme="brandingData.sectionRegisterBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sectionRegisterBorderColor" />

                <Input :textColorLabel="'Title Color'" :colourScheme="brandingData.sectionRegisterTitleColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sectionRegisterTitleColor" />

                <Input :textColorLabel="' Item Cont Color'" :colourScheme="brandingData.sectionRegisterItemContColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sectionRegisterItemContColor" />

                <Input :textColorLabel="'Item Cont Border Color'"
                  :colourScheme="brandingData.sectionRegisterItemContBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sectionRegisterItemContBorderColor" />

                <Input :textColorLabel="'Item Cont Background'"
                  :colourScheme="brandingData.sectionRegisterItemContBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sectionRegisterItemContBackground" />

                <Input :textColorLabel="'Item Cont Hover Color'"
                  :colourScheme="brandingData.sectionRegisterItemContHoverColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sectionRegisterItemContHoverColor" />

                <Input :textColorLabel="' Item Cont Hover Border Color'"
                  :colourScheme="brandingData.sectionRegisterItemContHoverBorderColor"
                  @selectedValueColorChange="selectedValueColorChange"
                  colorValue="sectionRegisterItemContHoverBorderColor" />

                <Input :textColorLabel="'Item Cont Hover Background'"
                  :colourScheme="brandingData.sectionRegisterItemContHoverBackground"
                  @selectedValueColorChange="selectedValueColorChange"
                  colorValue="sectionRegisterItemContHoverBackground" />
              </Accordion>
            </div>
          </div>

          <!-- Section Clients -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="Section Clients">
                <Input :textColorLabel="'Background'" :colourScheme="brandingData.sectionClientsBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sectionClientsBackground" />

                <Input :textColorLabel="'Border Color'" :colourScheme="brandingData.sectionClientsBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sectionClientsBorderColor" />

                <Input :textColorLabel="' Text Color'" :colourScheme="brandingData.sectionClientsTextColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sectionClientsTextColor" />

              </Accordion>
            </div>
          </div>

          <!-- Section Distribution -->
          <div class="brandingData">
            <Accordion textColorLabelHeading="Section Distribution">
              <Input :textColorLabel="' Background'" :colourScheme="brandingData.sectionDistributionBackground"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionDistributionBackground" />

              <Input :textColorLabel="'Border Color'" :colourScheme="brandingData.sectionDistributionBorderColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionDistributionBorderColor" />

              <Input :textColorLabel="' Text Color'" :colourScheme="brandingData.sectionDistributionTextColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionDistributionTextColor" />

              <Input :textColorLabel="' Button Color'" :colourScheme="brandingData.sectionDistributionButtonColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionDistributionButtonColor" />

              <Input :textColorLabel="'Button Background'"
                :colourScheme="brandingData.sectionDistributionButtonBackground"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionDistributionButtonBackground" />

              <Input :textColorLabel="'Button Border Color'"
                :colourScheme="brandingData.sectionDistributionButtonBorderColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionDistributionButtonBorderColor" />

              <Input :textColorLabel="'Button Hover Color'"
                :colourScheme="brandingData.sectionDistributionButtonHoverColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionDistributionButtonHoverColor" />

              <Input :textColorLabel="'Button Hover Background'"
                :colourScheme="brandingData.sectionDistributionButtonHoverBackground"
                @selectedValueColorChange="selectedValueColorChange"
                colorValue="sectionDistributionButtonHoverBackground" />

              <Input :textColorLabel="'Button Hover Border Color'"
                :colourScheme="brandingData.sectionDistributionButtonHoverBorderColor"
                @selectedValueColorChange="selectedValueColorChange"
                colorValue="sectionDistributionButtonHoverBorderColor" />
            </Accordion>
          </div>

        </div>

        <div class="col-sm-12 col-md-4 col-lg-4">

          <!-- Home Header Boxes -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="Home Header Boxes">
                <!-- Box 1 -->
                <div class="box-1">
                  <Input :textColorLabel="' Box 1 Color'" :colourScheme="brandingData.headerBox1Color"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox1Color" />

                  <Input :textColorLabel="'Box 1 Background'" :colourScheme="brandingData.headerBox1Background"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox1Background" />

                  <Input :textColorLabel="'Box 1 Border Color'" :colourScheme="brandingData.headerBox1BorderColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox1BorderColor" />

                  <Input :textColorLabel="' Box 1 Button Box Color'" :colourScheme="brandingData.headerBox1ButtonBoxColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox1ButtonBoxColor" />

                  <Input :textColorLabel="'Box 1 Button Box Background'"
                    :colourScheme="brandingData.headerBox1ButtonBoxBackground"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox1ButtonBoxBackground" />

                  <Input :textColorLabel="'Box 1 Button Box Border Color'"
                    :colourScheme="brandingData.headerBox1ButtonBoxBorderColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox1ButtonBoxBorderColor" />

                  <Input :textColorLabel="'Box 1 Button Hover Box Color'"
                    :colourScheme="brandingData.headerBox1ButtonHoverBoxColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox1ButtonHoverBoxColor" />

                  <Input :textColorLabel="'Box 1 Button Hover Box Background'"
                    :colourScheme="brandingData.headerBox1ButtonHoverBoxBackground"
                    @selectedValueColorChange="selectedValueColorChange"
                    colorValue="headerBox1ButtonHoverBoxBackground" />

                  <Input :textColorLabel="'Box 1 Button Hover Box Border Color'"
                    :colourScheme="brandingData.headerBox1ButtonHoverBoxBorderColor"
                    @selectedValueColorChange="selectedValueColorChange"
                    colorValue="headerBox1ButtonHoverBoxBorderColor" />
                </div>

                <!-- Box 2 -->
                <div class="box-2">
                  <Input :textColorLabel="' Box 2 Color'" :colourScheme="brandingData.headerBox2Color"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox2Color" />

                  <Input :textColorLabel="'Box 2 Background'" :colourScheme="brandingData.headerBox2Background"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox2Background" />

                  <Input :textColorLabel="'Box 2 Border Color'" :colourScheme="brandingData.headerBox2BorderColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox2BorderColor" />

                  <Input :textColorLabel="' Box 2 Button Box Color'" :colourScheme="brandingData.headerBox2ButtonBoxColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox2ButtonBoxColor" />

                  <Input :textColorLabel="'Box 2 Button Box Background'"
                    :colourScheme="brandingData.headerBox2ButtonBoxBackground"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox2ButtonBoxBackground" />

                  <Input :textColorLabel="'Box 2 Button Box Border Color'"
                    :colourScheme="brandingData.headerBox2ButtonBoxBorderColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox2ButtonBoxBorderColor" />

                  <Input :textColorLabel="'Box 2 Button Hover Box Color'"
                    :colourScheme="brandingData.headerBox2ButtonHoverBoxColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox2ButtonHoverBoxColor" />

                  <Input :textColorLabel="'Box 2 Button Hover Box Background'"
                    :colourScheme="brandingData.headerBox2ButtonHoverBoxBackground"
                    @selectedValueColorChange="selectedValueColorChange"
                    colorValue="headerBox2ButtonHoverBoxBackground" />

                  <Input :textColorLabel="'Box 2 Button Hover Box Border Color'"
                    :colourScheme="brandingData.headerBox2ButtonHoverBoxBorderColor"
                    @selectedValueColorChange="selectedValueColorChange"
                    colorValue="headerBox2ButtonHoverBoxBorderColor" />
                </div>

                <!-- Box 3 -->
                <div class="box-3">
                  <Input :textColorLabel="' Box 3 Color'" :colourScheme="brandingData.headerBox3Color"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox3Color" />

                  <Input :textColorLabel="'Box 3 Background'" :colourScheme="brandingData.headerBox3Background"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox3Background" />

                  <Input :textColorLabel="'Box 3 Border Color'" :colourScheme="brandingData.headerBox3BorderColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox3BorderColor" />

                  <Input :textColorLabel="' Box 3 Button Box Color'" :colourScheme="brandingData.headerBox3ButtonBoxColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox3ButtonBoxColor" />

                  <Input :textColorLabel="'Box 3 Button Box Background'"
                    :colourScheme="brandingData.headerBox3ButtonBoxBackground"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox3ButtonBoxBackground" />

                  <Input :textColorLabel="'Box 3 Button Box Border Color'"
                    :colourScheme="brandingData.headerBox3ButtonBoxBorderColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox3ButtonBoxBorderColor" />

                  <Input :textColorLabel="'Box 3 Button Hover Box Color'"
                    :colourScheme="brandingData.headerBox3ButtonHoverBoxColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox3ButtonHoverBoxColor" />

                  <Input :textColorLabel="'Box 3 Button Hover Box Background'"
                    :colourScheme="brandingData.headerBox3ButtonHoverBoxBackground"
                    @selectedValueColorChange="selectedValueColorChange"
                    colorValue="headerBox3ButtonHoverBoxBackground" />

                  <Input :textColorLabel="'Box 3 Button Hover Box Border Color'"
                    :colourScheme="brandingData.headerBox3ButtonHoverBoxBorderColor"
                    @selectedValueColorChange="selectedValueColorChange"
                    colorValue="headerBox3ButtonHoverBoxBorderColor" />
                </div>

                <!-- Box 4 -->
                <div class="box-4">
                  <Input :textColorLabel="' Box 4 Color'" :colourScheme="brandingData.headerBox4Color"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox4Color" />

                  <Input :textColorLabel="'Box 4 Background'" :colourScheme="brandingData.headerBox4Background"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox4Background" />

                  <Input :textColorLabel="'Box 4 Border Color'" :colourScheme="brandingData.headerBox4BorderColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox4BorderColor" />

                  <Input :textColorLabel="' Box 4 Button Box Color'" :colourScheme="brandingData.headerBox4ButtonBoxColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox4ButtonBoxColor" />

                  <Input :textColorLabel="'Box 4 Button Box Background'"
                    :colourScheme="brandingData.headerBox4ButtonBoxBackground"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox4ButtonBoxBackground" />

                  <Input :textColorLabel="'Box 4 Button Box Border Color'"
                    :colourScheme="brandingData.headerBox4ButtonBoxBorderColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox4ButtonBoxBorderColor" />

                  <Input :textColorLabel="'Box 4 Button Hover Box Color'"
                    :colourScheme="brandingData.headerBox4ButtonHoverBoxColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox4ButtonHoverBoxColor" />

                  <Input :textColorLabel="'Box 4 Button Hover Box Background'"
                    :colourScheme="brandingData.headerBox4ButtonHoverBoxBackground"
                    @selectedValueColorChange="selectedValueColorChange"
                    colorValue="headerBox4ButtonHoverBoxBackground" />

                  <Input :textColorLabel="'Box 4 Button Hover Box Border Color'"
                    :colourScheme="brandingData.headerBox4ButtonHoverBoxBorderColor"
                    @selectedValueColorChange="selectedValueColorChange"
                    colorValue="headerBox4ButtonHoverBoxBorderColor" />
                </div>

                <!-- Box 5 -->
                <div class="box-4">
                  <Input :textColorLabel="' Box 5 Color'" :colourScheme="brandingData.headerBox5Color"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox5Color" />

                  <Input :textColorLabel="'Box 5 Background'" :colourScheme="brandingData.headerBox5Background"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox5Background" />

                  <Input :textColorLabel="'Box 5 Border Color'" :colourScheme="brandingData.headerBox5BorderColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox5BorderColor" />

                  <Input :textColorLabel="' Box 5 Button Box Color'" :colourScheme="brandingData.headerBox5ButtonBoxColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox5ButtonBoxColor" />

                  <Input :textColorLabel="'Box 5 Button Box Background'"
                    :colourScheme="brandingData.headerBox5ButtonBoxBackground"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox5ButtonBoxBackground" />

                  <Input :textColorLabel="'Box 5 Button Box Border Color'"
                    :colourScheme="brandingData.headerBox5ButtonBoxBorderColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox5ButtonBoxBorderColor" />

                  <Input :textColorLabel="'Box 5 Button Hover Box Color'"
                    :colourScheme="brandingData.headerBox5ButtonHoverBoxColor"
                    @selectedValueColorChange="selectedValueColorChange" colorValue="headerBox5ButtonHoverBoxColor" />

                  <Input :textColorLabel="'Box 5 Button Hover Box Background'"
                    :colourScheme="brandingData.headerBox5ButtonHoverBoxBackground"
                    @selectedValueColorChange="selectedValueColorChange"
                    colorValue="headerBox5ButtonHoverBoxBackground" />

                  <Input :textColorLabel="'Box 5 Button Hover Box Border Color'"
                    :colourScheme="brandingData.headerBox5ButtonHoverBoxBorderColor"
                    @selectedValueColorChange="selectedValueColorChange"
                    colorValue="headerBox5ButtonHoverBoxBorderColor" />
                </div>
              </Accordion>
            </div>
          </div>

          <!-- Products Section -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="Products Section">
                <Input :textColorLabel="'Title Color'" :colourScheme="brandingData.productsSectionTitleColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="productsSectionTitleColor" />

                <Input :textColorLabel="' Title Hover Color'" :colourScheme="brandingData.productsSectionTitleHoverColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="productsSectionTitleHoverColor" />

                <Input :textColorLabel="'Icon Color'" :colourScheme="brandingData.productsSectionIconColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="productsSectionIconColor" />

                <Input :textColorLabel="'Cell Highlighted Border Color'"
                  :colourScheme="brandingData.productsCellHighlightedBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="productsCellHighlightedBorderColor" />

                <Input :textColorLabel="'Cell Highlighted Name Color'"
                  :colourScheme="brandingData.cellHighlightedNameColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="cellHighlightedNameColor" />

                <Input :textColorLabel="'Cell Highlighted Prie Color'"
                  :colourScheme="brandingData.cellHighlightedPrieColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="cellHighlightedPrieColor" />

                <Input :textColorLabel="'Cell Highlighted New Prie Color'"
                  :colourScheme="brandingData.cellHighlightedNewPrieColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="cellHighlightedNewPrieColor" />

                <Input :textColorLabel="'Cell Highlighted Discount Info Color'"
                  :colourScheme="brandingData.cellHighlightedDicountInfoColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="cellHighlightedDicountInfoColor" />

                <Input :textColorLabel="'Cell Highlighted Discount InfoBackground'"
                  :colourScheme="brandingData.cellHighlightedDicountInfoBackground"
                  @selectedValueColorChange="selectedValueColorChange"
                  colorValue="cellHighlightedDicountInfoBackground" />

                <Input :textColorLabel="'Cell Highlighted Discount Info Border Color'"
                  :colourScheme="brandingData.cellHighlightedDicountInfoBorderColor"
                  @selectedValueColorChange="selectedValueColorChange"
                  colorValue="cellHighlightedDicountInfoBorderColor" />

                <Input :textColorLabel="'Cell Highlighted Button Color'"
                  :colourScheme="brandingData.cellHighlightedButtonColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="cellHighlightedButtonColor" />

                <Input :textColorLabel="'Cell Highlighted Button Background'"
                  :colourScheme="brandingData.cellHighlightedButtonBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="cellHighlightedButtonBackground" />

                <Input :textColorLabel="'Cell Highlighted Button Border Color'"
                  :colourScheme="brandingData.cellHighlightedButtonBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="cellHighlightedButtonBorderColor" />

                <Input :textColorLabel="'Cell Highlighted Button Hover Color'"
                  :colourScheme="brandingData.cellHighlightedButtonHoverColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="cellHighlightedButtonHoverColor" />

                <Input :textColorLabel="'Cell Highlighted Button Hover Background'"
                  :colourScheme="brandingData.cellHighlightedButtonHoverBackground"
                  @selectedValueColorChange="selectedValueColorChange"
                  colorValue="cellHighlightedButtonHoverBackground" />

                <Input :textColorLabel="'Cell Highlighted Button Hover Border Color'"
                  :colourScheme="brandingData.cellHighlightedButtonHoverBorderColor"
                  @selectedValueColorChange="selectedValueColorChange"
                  colorValue="cellHighlightedButtonHoverBorderColor" />

                <Input :textColorLabel="'Item Product Background Color'"
                  :colourScheme="brandingData.itemProductBackgroundColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="itemProductBackgroundColor" />

                <Input :textColorLabel="'Item Product Border Color'" :colourScheme="brandingData.itemProductBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="itemProductBorderColor" />

                <Input :textColorLabel="'Item Product Title Color'" :colourScheme="brandingData.itemProductTitleColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="itemProductTitleColor" />

                <Input :textColorLabel="'Item Product Price Color'" :colourScheme="brandingData.itemProductPriceColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="itemProductPriceColor" />

                <Input :textColorLabel="'Item Product Discount Info Color'"
                  :colourScheme="brandingData.itemProductDiscountInfoColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="itemProductDiscountInfoColor" />

                <Input :textColorLabel="'Item Product Discount Info Background'"
                  :colourScheme="brandingData.itemProductDiscountInfoBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="itemProductDiscountInfoBackground" />

                <Input :textColorLabel="'Item Product Discount Info Border Color'"
                  :colourScheme="brandingData.itemProductDiscountInfoBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="itemProductDiscountInfoBorderColor" />
              </Accordion>
            </div>
          </div>


          <!-- Section Review Clients -->
          <div class="brandingData">
            <Accordion textColorLabelHeading="Section Review Clients">
              <Input :textColorLabel="'Background'" :colourScheme="brandingData.sectionReviewClientsBackground"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionReviewClientsBackground" />

              <Input :textColorLabel="'Border Color'" :colourScheme="brandingData.sectionReviewClientsBorderColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionReviewClientsBorderColor" />

              <Input :textColorLabel="'Stars Color'" :colourScheme="brandingData.sectionReviewClientsStarsColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionReviewClientsStarsColor" />

              <Input :textColorLabel="'Text Color'" :colourScheme="brandingData.sectionReviewClientsTextColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionReviewClientsTextColor" />
            </Accordion>
          </div>


          <!-- Section Slider Review Clients -->
          <div class="brandingData">
            <Accordion textColorLabelHeading="Section Slider Review Clients">
              <Input :textColorLabel="'Background'" :colourScheme="brandingData.sectionSliderReviewClientsBackground"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionSliderReviewClientsBackground" />

              <Input :textColorLabel="'Border Color'" :colourScheme="brandingData.sectionSliderReviewClientsBorderColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionSliderReviewClientsBorderColor" />

              <Input :textColorLabel="'Stars Color'" :colourScheme="brandingData.sectionSliderReviewClientsStarsColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionSliderReviewClientsStarsColor" />

              <Input :textColorLabel="'Text Color'" :colourScheme="brandingData.sectionSliderReviewClientsTextColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionSliderReviewClientsTextColor" />

              <Input :textColorLabel="'Button Color'" :colourScheme="brandingData.sectionSliderReviewClientsButtonColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionSliderReviewClientsButtonColor" />

              <Input :textColorLabel="'Button Background'"
                :colourScheme="brandingData.sectionSliderReviewClientsButtonBackground"
                @selectedValueColorChange="selectedValueColorChange"
                colorValue="sectionSliderReviewClientsButtonBackground" />

              <Input :textColorLabel="'Button Border Color'"
                :colourScheme="brandingData.sectionSliderReviewClientsButtonBorderColor"
                @selectedValueColorChange="selectedValueColorChange"
                colorValue="sectionSliderReviewClientsButtonBorderColor" />

              <Input :textColorLabel="'Button Hover Color'"
                :colourScheme="brandingData.sectionSliderReviewClientsButtonHoverColor"
                @selectedValueColorChange="selectedValueColorChange"
                colorValue="sectionSliderReviewClientsButtonHoverColor" />

              <Input :textColorLabel="'Button Hover Background'"
                :colourScheme="brandingData.sectionSliderReviewClientsButtonHoverBackground"
                @selectedValueColorChange="selectedValueColorChange"
                colorValue="sectionSliderReviewClientsButtonHoverBackground" />

              <Input :textColorLabel="'Button Hover Border Color'"
                :colourScheme="brandingData.sectionSliderReviewClientsButtonHoverBorderColor"
                @selectedValueColorChange="selectedValueColorChange"
                colorValue="sectionSliderReviewClientsButtonHoverBorderColor" />
            </Accordion>
          </div>


          <!-- Section Newsletter -->
          <div class="brandingData">
            <Accordion textColorLabelHeading="Section Newsletter">
              <Input :textColorLabel="'Background'" :colourScheme="brandingData.sectionNewletterBackground"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionNewletterBackground" />

              <Input :textColorLabel="'Border Color'" :colourScheme="brandingData.sectionNewletterBorderColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionNewletterBorderColor" />

              <Input :textColorLabel="'Text Color'" :colourScheme="brandingData.sectionNewletterTextColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionNewletterTextColor" />

              <Input :textColorLabel="' Button Color'" :colourScheme="brandingData.sectionNewletterButtonColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionNewletterButtonColor" />

              <Input :textColorLabel="'Button Background'" :colourScheme="brandingData.sectionNewletterButtonBackground"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionNewletterButtonBackground" />

              <Input :textColorLabel="'Button Border Color'"
                :colourScheme="brandingData.sectionNewletterButtonBorderColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionNewletterButtonBorderColor" />

              <Input :textColorLabel="'Button Hover Color'" :colourScheme="brandingData.sectionNewletterButtonHoverColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionNewletterButtonHoverColor" />

              <Input :textColorLabel="'Button Hover Background'"
                :colourScheme="brandingData.sectionNewletterButtonHoverBackground"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionNewletterButtonHoverBackground" />

              <Input :textColorLabel="' Button Hover Border Color'"
                :colourScheme="brandingData.sectionNewletterButtonHoverBorderColor"
                @selectedValueColorChange="selectedValueColorChange"
                colorValue="sectionNewletterButtonHoverBorderColor" />
            </Accordion>
          </div>

          <!-- Section It-ist -->
          <div class="brandingData">
            <Accordion textColorLabelHeading="Section It-ist">
              <Input :textColorLabel="'Background'" :colourScheme="brandingData.sectionItBackground"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionItBackground" />

              <Input :textColorLabel="' Border Color'" :colourScheme="brandingData.sectionItBorderColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionItBorderColor" />

              <Input :textColorLabel="'Text Color'" :colourScheme="brandingData.sectionItTextColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionItTextColor" />

              <Input :textColorLabel="'Button Color'" :colourScheme="brandingData.sectionItButtonColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionItButtonColor" />

              <Input :textColorLabel="'Button Background'" :colourScheme="brandingData.sectionItButtonBackground"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionItButtonBackground" />

              <Input :textColorLabel="'Button Border Color'" :colourScheme="brandingData.sectionItButtonBorderColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionItButtonBorderColor" />

              <Input :textColorLabel="'Button Hover Color'" :colourScheme="brandingData.sectionItButtonHoverColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionItButtonHoverColor" />

              <Input :textColorLabel="'Button Hover Background'"
                :colourScheme="brandingData.sectionItButtonHoverBackground"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionItButtonHoverBackground" />

              <Input :textColorLabel="' Button Hover Border Color'"
                :colourScheme="brandingData.sectionItButtonHoverBorderColor"
                @selectedValueColorChange="selectedValueColorChange" colorValue="sectionItButtonHoverBorderColor" />
            </Accordion>
          </div>

          <!-- Section consultants -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="Section consultants">
                <Input :textColorLabel="'Background'" :colourScheme="brandingData.sectionConsultantsBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sectionConsultantsBackground" />

                <Input :textColorLabel="'Border Color'" :colourScheme="brandingData.sectionConsultantsBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sectionConsultantsBorderColor" />

                <Input :textColorLabel="'Title Color'" :colourScheme="brandingData.sectionConsultantsTitleColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sectionConsultantsTitleColor" />

                <Input :textColorLabel="' Item Border Color'"
                  :colourScheme="brandingData.sectionConsultantsItemBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sectionConsultantsItemBorderColor" />

                <Input :textColorLabel="' Item Background'" :colourScheme="brandingData.sectionConsultantsItemBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sectionConsultantsItemBackground" />

                <Input :textColorLabel="'Item Text Color'" :colourScheme="brandingData.sectionConsultantsItemTextColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sectionConsultantsItemTextColor" />

                <Input :textColorLabel="'Item Stars Color'" :colourScheme="brandingData.sectionConsultantsItemStarsColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="sectionConsultantsItemStarsColor" />
              </Accordion>
            </div>
          </div>

          <!-- Footer -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="Footer">
                <Input :textColorLabel="'Background'" :colourScheme="brandingData.footerBackground"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="footerBackground" />

                <Input :textColorLabel="'Border Color'" :colourScheme="brandingData.footerBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="footerBorderColor" />

                <Input :textColorLabel="'Text Color'" :colourScheme="brandingData.footerTextColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="footerTextColor" />

                <Input :textColorLabel="'Column Title Color'" :colourScheme="brandingData.footerColumnTitleColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="footerColumnTitleColor" />

                <Input :textColorLabel="'Column Title Hover Color'"
                  :colourScheme="brandingData.footerColumnTitleHoverColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="footerColumnTitleHoverColor" />

                <Input :textColorLabel="' Column Item Color'" :colourScheme="brandingData.footerColumnItemColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="footerColumnItemColor" />

                <Input :textColorLabel="' Column Item Hover Color'"
                  :colourScheme="brandingData.footerColumnItemHoverColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="footerColumnItemHoverColor" />
              </Accordion>
            </div>
          </div>

          <!-- Product Page -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="Product Page">
                <Input :textColorLabel="'Breadcrumb Text Color'" :colourScheme="brandingData.breadcrumbTextColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="breadcrumbTextColor" />

                <Input :textColorLabel="'Product Title Color'" :colourScheme="brandingData.productTitleColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="productTitleColor" />

                <Input :textColorLabel="'Product Text Color'" :colourScheme="brandingData.productTextColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="productTextColor" />

                <Input :textColorLabel="'Product Price Color'" :colourScheme="brandingData.productPriceColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="productPriceColor" />

                <Input :textColorLabel="'Product Icon Color'" :colourScheme="brandingData.productIconColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="productIconColor" />

                <Input :textColorLabel="'Product Tab Background Color'"
                  :colourScheme="brandingData.productTabBackgroundColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="productTabBackgroundColor" />

                <Input :textColorLabel="'Product Tab Border Color'" :colourScheme="brandingData.productTabBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="productTabBorderColor" />

                <Input :textColorLabel="'Product Tab Item  Background Color'"
                  :colourScheme="brandingData.productTabItemBackgroundColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="productTabItemBackgroundColor" />

                <Input :textColorLabel="'Product Tab Item  Border Color'"
                  :colourScheme="brandingData.productTabItemBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="productTabItemBorderColor" />

                <Input :textColorLabel="'Product Tab Item   Color'" :colourScheme="brandingData.productTabItemColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="productTabItemColor" />


                <Input :textColorLabel="'Product Tab Item Active Background Color'"
                  :colourScheme="brandingData.productTabItemActiveBackgroundColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="productTabItemActiveBackgroundColor" />

                <Input :textColorLabel="'Product Tab Item Active Border Color'"
                  :colourScheme="brandingData.productTabItemActiveBorderColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="productTabItemActiveBorderColor" />

                <Input :textColorLabel="'Product Tab Item Active  Color'"
                  :colourScheme="brandingData.productTabItemActiveColor"
                  @selectedValueColorChange="selectedValueColorChange" colorValue="productTabItemActiveColor" />
              </Accordion>
            </div>
          </div>

          <!-- Logo & Favicon -->
          <div class="branding-section">
            <div class="brandingData">
              <Accordion textColorLabelHeading="Logo & Favicon">
                <div class="pr-4 pl-4 pb-4">
                  <div class="row ">
                    <div class="logo col-sm-12  col-lg-12">
                      <p class="branding-color-text mb-3">Logo URL (Logo height between 130px to 140 px)</p>
                      <input class="image-url-input-field   width-100 " id="logofile_url" type="text"
                        :value="logofile_url"
                        style="opacity: 1; background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);"
                        @change="changeLogoImage" />
                      <div class="mb-4 mt-5">
                        <div v-if="valid_logofile_url" style="position: relative;">
                          <div class="close-icon" @click="removeImg('logo')">
                            <svg width="22" height="22" viewBox="0 0 20 20" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M10 1.25C5.125 1.25 1.25 5.125 1.25 10C1.25 14.875 5.125 18.75 10 18.75C14.875 18.75 18.75 14.875 18.75 10C18.75 5.125 14.875 1.25 10 1.25ZM13.375 14.375L10 11L6.625 14.375L5.625 13.375L9 10L5.625 6.625L6.625 5.625L10 9L13.375 5.625L14.375 6.625L11 10L14.375 13.375L13.375 14.375Z"
                                fill="#DA5757" />
                            </svg>
                          </div>
                          <img class="banner-img" :src="logofile_url" alt="logo image" height="107px" width="107px" />
                        </div>
                        <div v-else className="banner-skeleton" style="background:#E2E2EA;width:107px;height:107px">
                          <div style="text-align: center;padding: 30px">
                            <img :src="require('@/assets/images/logo/card_image.png')" alt="logo image" height="49"
                              width="49" />
                          </div>
                        </div>
                      </div>

                      <v-btn color="primary" class="primary-button"
                        :loading="'logofile_url' === fileType ? loadingLogo : false" :disabled="!valid_logofile_url"
                        @click="updateLogoUrlsFavIcon('logofile_url', logofile_url)">
                        Încărcați
                      </v-btn>
                    </div>
                    <div class="favicon  col-sm-12  col-lg-12">
                      <div>
                        <p class="branding-color-text mb-3">Favicon URL (Icon height between 130px to 140 px) </p>
                        <!-- <input ref="faviconRef" type="file" hidden accept="image/png,image/jpeg" @change="changeFaviconImage" /> -->
                        <input class="image-url-input-field   width-100" id="fevicon_url" type="text" :value="fevicon_url"
                          style="opacity: 1; background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);"
                          @change="changeFaviconImage" />
                        <div class="mb-4 mt-5">
                          <div v-if="valid_fevicon_url" style="position: relative;">
                            <div class="close-icon" @click="removeImg('favicon')">
                              <svg width="22" height="22" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M10 1.25C5.125 1.25 1.25 5.125 1.25 10C1.25 14.875 5.125 18.75 10 18.75C14.875 18.75 18.75 14.875 18.75 10C18.75 5.125 14.875 1.25 10 1.25ZM13.375 14.375L10 11L6.625 14.375L5.625 13.375L9 10L5.625 6.625L6.625 5.625L10 9L13.375 5.625L14.375 6.625L11 10L14.375 13.375L13.375 14.375Z"
                                  fill="#DA5757" />
                              </svg>
                            </div>
                            <img class="banner-img" :src="fevicon_url" alt="Fav icon image" height="107px"
                              width="107px" />
                          </div>
                          <div v-else className="banner-skeleton" style="background:#E2E2EA;width:107px;height:107px">
                            <div style="text-align: center;padding: 30px">
                              <img :src="require('@/assets/images/logo/card_image.png')" alt="Fav icon image" height="49"
                                width="49" />
                            </div>
                          </div>
                        </div>
                        <v-btn color="primary" class="primary-button"
                          :loading="'fevicon_url' === fileType ? loadingLogo : false" :disabled="!valid_fevicon_url"
                          @click="updateLogoUrlsFavIcon('fevicon_url', fevicon_url)">
                          Încărcați
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion>
            </div>
          </div>



        </div>

        <div class="col-sm-12 col-md-4 col-lg-4 "> <v-btn color="primary" class="primary-button" :loading="loadingSave"
            @click="updateBranding">
            Salveaza
          </v-btn></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

let baseURL = process.env.VUE_APP_BASE_URL_API
baseURL = `${baseURL}/public`
import Loader from '@/components/Loader'
import Accordion from './Accordion';
import Input from './Input';
import ColorPicker from 'vue-color-picker-wheel';
export default {
  name: 'Branding',
  components: {
    Accordion, ColorPicker, Input,
    Loader
  },
  data() {
    return {
      logofile_url: "",
      valid_logofile_url: false,
      fevicon_url: '',
      valid_fevicon_url: false,
      fileType: '',
      colorValue: '#ff0000',
      loading: false,
      loadingSave: false,
      loadingLogo: false,
      selectedValue: '',
      brandingData: {
        //General
        generalFontColor: '',
        bodyBackground: '',
        primaryColor: '',
        secondaryColor: '',
        primaryBackgroundColor: '',
        secondaryBackgroundColor: '',
        primaryHoverColor: '',
        primaryHoverBackgroundColor: '',
        secondaryHoverColor: '',
        secondaryHoverBackgroundColor: '',

        //Primary Button
        primaryButtonColor: '',
        primaryButtonBackground: '',
        primaryButtonBorderColor: '',
        primaryButtonHoverColor: '',
        primaryButtonHoverBackground: '',
        primaryButtonHoverBorderColor: '',

        //Secondary Button
        secondaryButtonColor: '',
        secondaryButtonBackground: '',
        secondaryButtonBorderColor: '',
        secondaryButtonHoverColor: '',
        secondaryButtonHoverBackground: '',
        secondaryButtonHoverBorderColor: '',

        //Third Button
        thirdButtonColor: '',
        thirdButtonBackground: '',
        thirdButtonBorderColor: '',
        thirdButtonHoverColor: '',
        thirdButtonHoverBackground: '',
        thirdButtonHoverBorderColor: '',

        //Header
        headerBackground: '',
        headerFontColor: '',
        headerInputColor: '',
        headerInputBackground: '',
        headerInputBorderColor: '',
        headerButtonColor: '',
        headerButtonBackground: '',
        headerButtonBorderColor: '',
        headerButtonHoverColor: '',
        headerButtonHoverBackground: '',
        headerButtonHoverBorderColor: '',
        headerIconColor: '',
        headerIconHoverColor: '',

        //Menu
        menuBackground: '',
        menuItemBackground: '',
        menuItemColor: '',
        subMenuBackground: '',
        subMenuItemColor: '',
        subMenuItemBackground: '',
        subMenuItemHoverColor: '',
        subMenuItemHoverBackground: '',

        //Slider Home
        sliderDotsBorderColor: '',
        sliderDotsBackground: '',
        sliderDotsHoverBackground: '',
        sliderDotsHoverBorderColor: '',
        sliderDotsActiveBorderColor: '',
        sliderDotsActiveBackground: '',
        sliderButtonBackground: '',
        sliderButtonColor: '',
        sliderButtonBorderColor: '',
        sliderButtonHoverBackground: '',
        sliderButtonHoverColor: '',
        sliderButtonHoverBorderColor: '',
        sliderContentColor: '',
        sliderDicountInfoColor: '',
        sliderDicountInfoBackground: '',
        sliderDicountInfoBorderColor: '',

        // Home Header Boxes
        //Box 1
        headerBox1Color: '',
        headerBox1Background: '',
        headerBox1BorderColor: '',
        headerBox1ButtonBoxColor: '',
        headerBox1ButtonBoxBackground: '',
        headerBox1ButtonBoxBorderColor: '',
        headerBox1ButtonHoverBoxColor: '',
        headerBox1ButtonHoverBoxBackground: '',
        headerBox1ButtonHoverBoxBorderColor: '',
        //Box 2
        headerBox2Color: '',
        headerBox2Background: '',
        headerBox2BorderColor: '',
        headerBox2ButtonBoxColor: '',
        headerBox2ButtonBoxBackground: '',
        headerBox2ButtonBoxBorderColor: '',
        headerBox2ButtonHoverBoxColor: '',
        headerBox2ButtonHoverBoxBackground: '',
        headerBox2ButtonHoverBoxBorderColor: '',
        //Box 3
        headerBox3Color: '',
        headerBox3Background: '',
        headerBox3BorderColor: '',
        headerBox3ButtonBoxColor: '',
        headerBox3ButtonBoxBackground: '',
        headerBox3ButtonBoxBorderColor: '',
        headerBox3ButtonHoverBoxColor: '',
        headerBox3ButtonHoverBoxBackground: '',
        headerBox3ButtonHoverBoxBorderColor: '',
        //Box 4
        headerBox4Color: '',
        headerBox4Background: '',
        headerBox4BorderColor: '',
        headerBox4ButtonBoxColor: '',
        headerBox4ButtonBoxBackground: '',
        headerBox4ButtonBoxBorderColor: '',
        headerBox4ButtonHoverBoxColor: '',
        headerBox4ButtonHoverBoxBackground: '',
        headerBox4ButtonHoverBoxBorderColor: '',
        //Box 5
        headerBox5Color: '',
        headerBox5Background: '',
        headerBox5BorderColor: '',
        headerBox5ButtonBoxColor: '',
        headerBox5ButtonBoxBackground: '',
        headerBox5ButtonBoxBorderColor: '',
        headerBox5ButtonHoverBoxColor: '',
        headerBox5ButtonHoverBoxBackground: '',
        headerBox5ButtonHoverBoxBorderColor: '',

        //Products Section
        productsSectionTitleColor: '',
        productsSectionTitleHoverColor: '',
        productsSectionIconColor: '',
        productsCellHighlightedBorderColor: '',
        cellHighlightedNameColor: '',
        cellHighlightedPrieColor: '',
        cellHighlightedNewPrieColor: '',
        cellHighlightedDicountInfoColor: '',
        cellHighlightedDicountInfoBackground: '',
        cellHighlightedDicountInfoBorderColor: '',
        cellHighlightedButtonColor: '',
        cellHighlightedButtonBackground: '',
        cellHighlightedButtonBorderColor: '',
        cellHighlightedButtonHoverColor: '',
        cellHighlightedButtonHoverBackground: '',
        cellHighlightedButtonHoverBorderColor: '',
        itemProductBackgroundColor: '',
        itemProductBorderColor: '',
        itemProductTitleColor: '',
        itemProductPriceColor: '',
        itemProductDiscountInfoColor: '',
        itemProductDiscountInfoBackground: '',
        itemProductDiscountInfoBorderColor: '',

        //Section Review Clients
        sectionReviewClientsBackground: '',
        sectionReviewClientsBorderColor: '',
        sectionReviewClientsStarsColor: '',
        sectionReviewClientsTextColor: '',

        //Section Slider Review Clients
        sectionSliderReviewClientsBackground: '',
        sectionSliderReviewClientsBorderColor: '',
        sectionSliderReviewClientsStarsColor: '',
        sectionSliderReviewClientsTextColor: '',
        sectionSliderReviewClientsButtonColor: '',
        sectionSliderReviewClientsButtonBackground: '',
        sectionSliderReviewClientsButtonBorderColor: '',
        sectionSliderReviewClientsButtonHoverColor: '',
        sectionSliderReviewClientsButtonHoverBackground: '',
        sectionSliderReviewClientsButtonHoverBorderColor: '',

        //Section Distribution
        sectionDistributionBackground: '',
        sectionDistributionBorderColor: '',
        sectionDistributionTextColor: '',
        sectionDistributionButtonColor: '',
        sectionDistributionButtonBackground: '',
        sectionDistributionButtonBorderColor: '',
        sectionDistributionButtonHoverColor: '',
        sectionDistributionButtonHoverBackground: '',
        sectionDistributionButtonHoverBorderColor: '',

        //Section Newsletter
        sectionNewletterBackground: '',
        sectionNewletterBorderColor: '',
        sectionNewletterTextColor: '',
        sectionNewletterButtonColor: '',
        sectionNewletterButtonBackground: '',
        sectionNewletterButtonBorderColor: '',
        sectionNewletterButtonHoverColor: '',
        sectionNewletterButtonHoverBackground: '',
        sectionNewletterButtonHoverBorderColor: '',

        //Section It-ist
        sectionItBackground: '',
        sectionItBorderColor: '',
        sectionItTextColor: '',
        sectionItButtonColor: '',
        sectionItButtonBackground: '',
        sectionItButtonBorderColor: '',
        sectionItButtonHoverColor: '',
        sectionItButtonHoverBackground: '',
        sectionItButtonHoverBorderColor: '',

        //Section Register
        sectionRegisterBackground: '',
        sectionRegisterBorderColor: '',
        sectionRegisterTitleColor: '',
        sectionRegisterItemContColor: '',
        sectionRegisterItemContBorderColor: '',
        sectionRegisterItemContBackground: '',
        sectionRegisterItemContHoverColor: '',
        sectionRegisterItemContHoverBorderColor: '',
        sectionRegisterItemContHoverBackground: '',

        //Section consultants
        sectionConsultantsBackground: '',
        sectionConsultantsBorderColor: '',
        sectionConsultantsTitleColor: '',
        sectionConsultantsItemBorderColor: '',
        sectionConsultantsItemBackground: '',
        sectionConsultantsItemTextColor: '',
        sectionConsultantsItemStarsColor: '',

        //Section Clients
        sectionClientsBackground: '',
        sectionClientsBorderColor: '',
        sectionClientsTextColor: '',

        //Footer
        footerBackground: '',
        footerBorderColor: '',
        footerTextColor: '',
        footerColumnTitleColor: '',
        footerColumnTitleHoverColor: '',
        footerColumnItemColor: '',
        footerColumnItemHoverColor: '',

        //Product Page
        breadcrumbTextColor: '',
        productTitleColor: '',
        productTextColor: '',
        productPriceColor: '',
        productIconColor: '',
        productTabBackgroundColor: '',
        productTabBorderColor: '',
        productTabItemBackgroundColor: '',
        productTabItemBorderColor: '',
        productTabItemColor: '',
        productTabItemActiveBackgroundColor: '',
        productTabItemActiveBorderColor: '',
        productTabItemActiveColor: '',
      },
    }
  },
  computed: {
    items() {
      return this.brandingData
    }
  },
  props: {
    franchiseeId: {
      required: true
    }
  },
  methods: {
    onColorChange(color) {
      this.colorValue = color
      if (this.selectedValue && this.brandingData && Object.keys(this.brandingData).length > 0) {
        this.brandingData[this.selectedValue] = color
      }
    },
    selectedValueColorChange(value, selectedValue) {
      this.colorValue = value
      this.selectedValue = selectedValue
    },
    loadItems(isLoading) {
      if (isLoading)
        this.loading = true
      axios
        .get(baseURL + '/franchisees/getSchemaStructure/' + this.franchiseeId)
        .then(response => {
          if (response.data) {
            this.brandingData = response.data
          }
          this.loading = false
        })
    },
    getLogoUrlsForAdmin() {
      axios
        .get(baseURL + '/franchisees/getLogoUrlsForAdmin')
        .then(response => {
          if (response.data) {
            this.logofile_url = response.data.logofile_url
            if(response.data.logofile_url){
              this.valid_logofile_url = true
            }
            this.fevicon_url = response.data.fevicon_url
            if(response.data.fevicon_url){
              this.valid_fevicon_url = true
            }
          }
        })
    },
    loadItems(isLoading) {
      if (isLoading)
        this.loading = true
      axios
        .get(baseURL + '/franchisees/getSchemaStructure/' + this.franchiseeId)
        .then(response => {
          if (response.data) {
            this.brandingData = response.data
          }
          this.loading = false
        })
    },
    updateBranding() {
      this.loadingSave = true
      this.$http.post('/clients/franchisees/' + this.franchiseeId + '/updateBranding', this.brandingData)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Update branding!',
            color: 'success'
          })
          this.loadItems(false)
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'Error!',
            color: 'danger'
          })
        })
        .finally(() => {
          this.loadingSave = false
        })
    },
    updateLogoUrlsFavIcon(fileType, url) {
      this.fileType = fileType
      this.loadingLogo = true
      const data = {
        fileUrl: url,
        fileType: fileType
      }
      this.$http.patch('/clients/franchisees/' + this.franchiseeId + '/updateLogoUrlsForAdmin', data)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Update branding!',
            color: 'success'
          })
          this.loadItems(false)
          this.fileType = ''
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare!',
            text: 'Error!',
            color: 'danger'
          })
          this.fileType = ''
        })
        .finally(() => {
          this.loadingLogo = false
        })
    },
    changeLogoImage(e) {
      let extension =
        e.target.value &&
        e.target.value.substring(e.target.value.lastIndexOf('.') + 1);
      if (
        extension === 'png' ||
        extension === 'PNG' ||
        extension === 'jpg' ||
        extension === 'JPG' ||
        extension === 'jpeg' ||
        extension === 'JPEG'
      ) {
        this.logofile_url = e.target.value
        this.valid_logofile_url = true
      } else {
        this.$vs.notify({
          title: 'Eroare!',
          text: 'Please enter valid URL!',
          color: 'danger'
        })
        this.logofile_url = e.target.value
        this.valid_logofile_url = false
      }
    },
    changeFaviconImage(e) {
      let extension =
        e.target.value &&
        e.target.value.substring(e.target.value.lastIndexOf('.') + 1);
      if (
        extension === 'png' ||
        extension === 'PNG' ||
        extension === 'jpg' ||
        extension === 'JPG' ||
        extension === 'jpeg' ||
        extension === 'JPEG'
      ) {
        this.fevicon_url = e.target.value
        this.valid_fevicon_url = true
      } else {
        this.$vs.notify({
          title: 'Eroare!',
          text: 'Please enter valid URL!',
          color: 'danger'
        })
        this.fevicon_url = e.target.value
        this.valid_fevicon_url = false
      }
    },
    removeImg(checkLogoOrFavicon) {
      if (checkLogoOrFavicon === 'logo') {
        this.logofile_url = ""
        this.valid_logofile_url = false
      }
      if (checkLogoOrFavicon === 'favicon') {
        this.fevicon_url = ''
        this.valid_fevicon_url = false
      }
    },
  },

  created() {
    this.getLogoUrlsForAdmin()
    this.loadItems(true)
  }
}
</script>


<style lang="scss">
.accordion-section {
  display: block;
  width: 100%;
  // margin: 15px auto;
  margin: 0px 0px 25px 0px;
  background: #D9DFE8;
  border-radius: 4px;
}

.accordion-section .branding-header-section {
  position: relative;
  background: #D9DFE8;
  border-radius: 4px;
  transition: all 0.2s linear;
  padding: 15px;
  cursor: pointer;
}

.branding-header-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.576667px;
  color: #2F3842;
}

.accordion-section .branding-header-section::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%) rotate(0deg);
  width: 15px;
  height: 15px;
  background-image: url('./arrow-down-mint.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.2s linear;
  margin-right: 15px;
}

.accordion-section.open .branding-header-section {
  margin-bottom: 15px;
}

.accordion-section.open .branding-header-section::after {
  transform: translateY(-50%) rotate(180deg);
}

.accordion-section .branding-body-section {
  color: #3c3c3c;
  font-size: 18px;
  opacity: 0;
  max-height: 0px;
  // overflow-y: hidden;
  transition: all 0.2s ease-out;
  background: #EDF0F5;
  border-radius: 0px 0px 4px 4px;
}

.accordion-section.open .branding-body-section {
  opacity: 1;
  // max-height: 1000px;
  max-height: initial;
}

.branding-color-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.576667px;
  color: #2F3842;
  margin: 0 !important;
}

.color-input-field {
  width: 85px;
  height: 40px;
  float: left;
  clear: none;
  padding: 0 !important;
  text-align: center;
  left: 0 !important;
  border: 1px solid #d1d1d1;
  font-family: monospace;
}

.width-100 {
  width: 100%;
}

.color-input-field {
  height: 34px;
  float: left;
  clear: left;
  padding: 0 10px;
  border: 1px solid #e5e5e5;
  -ms-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none !important;
  -ms-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 4px;
  // background-color: #edf0f5;
  background-color: #fff;
}

.image-url-input-field {
  height: 40px;
  border: 1px solid #d1d1d1;
  padding: 0 10px;
  -ms-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none !important;
  -ms-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 4px;
  background-color: #fff;
}

.close-icon {
  position: absolute;
  left: 96px;
  top: -10px;
  cursor: pointer;
}
</style>

