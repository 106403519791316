<template>
  <v-sheet>
    <template slot="progress">

      <loader :loading="loading" colors="rgba(255, 255, 255, 0.90)" loader-color="#f0483d" size="fa-6x"></loader>

    </template>
    <v-card class="p-2" flat>
      <v-row class="mb-4 mt-5">

        <v-col cols="6">
          <v-text-field v-model="emailSettingsData.mail_mailer" label="Mail Mailer" />
          <error-messages :errors="errors.mail_mailer" />
        </v-col>

        <v-col cols="6">
          <v-text-field v-model="emailSettingsData.mailer_domain" label="Mailer Domain" />
          <error-messages :errors="errors.mailer_domain" />
        </v-col>

        <v-col cols="6">
          <v-text-field v-model="emailSettingsData.mailer_secret" label="Mailer Secret" />
          <error-messages :errors="errors.mailer_secret" />
        </v-col>

        <v-col cols="6">
          <v-text-field v-model="emailSettingsData.mailer_endpoint" label="Mailer Endpoint" />
          <error-messages :errors="errors.mailer_endpoint" />
        </v-col>

        <v-col cols="6">
          <v-text-field v-model="emailSettingsData.mail_from_address" label="Mail Form Address" />
          <error-messages :errors="errors.mail_from_address" />
        </v-col>

        <v-col cols="6">
          <v-text-field v-model="emailSettingsData.mail_from_name" label="Mail From Name" />
          <error-messages :errors="errors.mail_from_name" />
        </v-col>

      </v-row>
      <v-divider />
      <v-row class="mt-4">
        <v-col>
          <v-btn :disabled="loadingSave" :loading="loadingSave" color="success" class="primary-button"
            @click="updateEmailServerSettings">Salveaza</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-sheet>
</template>

<script>
import axios from 'axios'
import ErrorMessages from '@/components/general-form/ErrorMessages'
let baseURL = process.env.VUE_APP_BASE_URL_API
baseURL = `${baseURL}/public`
export default {
  name: 'EmailSettings',
  components: { ErrorMessages },
  data() {
    return {
      errors: {},
      emailSettingsData: {
        mail_mailer: '',
        mailer_domain: '',
        mailer_secret: '',
        mailer_endpoint: '',
        mail_from_address: '',
        mail_from_name: '',
      },
      loading: false,
      loadingSave: false,
    }
  },
  props: {
    franchiseeId: {
      required: true
    }
  },
  methods: {
    loadItems(isLoading) {
      if (isLoading)
        this.loading = true
      axios
        .get(baseURL + '/franchisees/getFranchiseeMailServerSettings/' + this.franchiseeId)
        .then(response => {
          if (response.data) {
            this.emailSettingsData = response.data
          }
          this.loading = false
        })
    },
    updateEmailServerSettings() {
      this.loadingSave = true
      this.$http.patch('/clients/franchisees/' + this.franchiseeId + '/updateEmailServerSettings', this.emailSettingsData)
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Updated email setting!',
            color: 'success'
          })
          this.loadItems(false)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            console.log(err.response.data.errors)
            this.errors = err.response.data.errors
            this.$vs.notify({
              title: 'Eroare!',
              text: 'Nu toate campurile obligatorii au fost completate.',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'A aparut o eroare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
        })
        .finally(() => {
          this.loadingSave = false
        })
    }
  },
  created() {
    this.loadItems(true)
  }

}
</script>
  